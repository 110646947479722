import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AdherentModel } from 'src/app/shared/models/adherent';
import { environment } from 'src/environments/environment';


const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class StatistiqueService {
// Http Options
httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

constructor(private httpClient: HttpClient) { }

  // Liste adherent
  getStatListAdherent(): Observable<AdherentModel[]> {

    return this.httpClient.get<AdherentModel[]>(`${API_URL}/stat/adherent/list`);
  }
  getStatListByDate(mois, annee): Observable<AdherentModel[]> {

    return this.httpClient.get<AdherentModel[]>(`${API_URL}/stat/adherent/filter/${mois}/${annee}`);
  }
  getStatListByDateItem(mois, annee, item, valItem): Observable<AdherentModel[]> {

    return this.httpClient.get<AdherentModel[]>(`${API_URL}/stat/adherent/filter/${mois}/${annee}/${item}/${valItem}`);
  }

}
