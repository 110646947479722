import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoginService } from './login.service';
import { UserService } from './user.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
authToken: string
accessToken: string
  constructor(private inject: Injector,private router: Router,private toastr: ToastrService ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authservice = this.inject.get(LoginService);
    const reqCopy = req.clone()
    // token Authentication
    this.authToken = authservice.getAuthToken()
    console.log('this.authToken',this.authToken);
    if(this.authToken){
      const jwtToken = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.authToken
        }
      });
       console.log('jwtToken',jwtToken);
      return next.handle(jwtToken).pipe(
        
        
     
       
     );
    }
    this.accessToken = authservice.GetAccessToken()
    console.log('this.accessToken',this.accessToken);
    if(this.accessToken){
      const jwtToken = req.clone({
        setHeaders: {
          Authorization: this.accessToken
        }
      });
       console.log('jwtToken',jwtToken);
      return next.handle(jwtToken).pipe(
        
        catchError((error): Observable<any> => {
          console.log('error',error);
          if (error.status === 401) {
            this.toastr.error( 'Vous n\'êtes pas autorisé');
            authservice.deconnexion()
          }else if (error.status === 403) {
            this.toastr.error('Accès refusé', 'session expiré');
            authservice.deconnexion()
          }else if (error.status === 500) {
            this.toastr.error('Accès refusé', 'Erreur serveur');
            throw new Error('Erreur serveur');
           
          }
          return of(null)
         
         })
        
       /*  
        map((event: HttpEvent<any>) => {
      
        console.log('event instanceof HttpResponse',event);
        
        if (event instanceof HttpResponse) {
          if (event.status === 401) {
            this.toastr.error('Connexion échouée', 'Vous n\'êtes pas autorisé');
            authservice.deconnexion()
          }else if (event.status === 403) {
            this.toastr.error('Accès refusé', 'Vous n\'avez pas les droits nécessaires');
            authservice.deconnexion()
          }
        }
        return event;
      }) */
      
      
      );
    }
  
    return next.handle(reqCopy);
  }
}
