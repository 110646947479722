import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alloted-rooms',
  templateUrl: './alloted-rooms.component.html',
  styleUrls: ['./alloted-rooms.component.scss']
})
export class AllotedRoomsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
