import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-row-loading',
  templateUrl: './table-row-loading.component.html',
  styleUrls: ['./table-row-loading.component.scss']
})
export class TableRowLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
