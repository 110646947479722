import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { StatistiqueService } from 'src/app/core/services/statistique.service';
import { AdherentModel } from 'src/app/shared/models/adherent';
import { environment } from 'src/environments/environment';
const API_URL = environment.backend_url;
@Component({
  selector: 'app-stat-table-adherent',
  templateUrl: './stat-table-adherent.component.html',
  styleUrls: ['./stat-table-adherent.component.scss']
})
export class StatTableAdherentComponent implements OnInit {
  @Input () TitleTablAdhStat: string;
  @Input () SubTitleTablAdhStat: string;
  
  private adherents: AdherentModel[];
  private Defaultadherents: AdherentModel[];
  profilUserName= '';
  profiluserPhone= '';
  profiluserAvatar= '';
  profilMatricule = '';
  profilBeneficiaire = '';

   statTotalAdh = '';
   statMoisAdh = '';
   statAnneAdh = '';

  loader = {active: false};
  searchValue: string;
  private q = '';
  listeMois = FormatDate.ListMonths();
  libelleMois='Mois';
  public filtreGroup: FormGroup;
  modalRef: BsModalRef;
  datefilter ;
  avatarUrl = null;
  apiUrl = API_URL;
  constructor(private statService: StatistiqueService, private router: Router, private store: Store,
              private fb: FormBuilder, private modalService: BsModalService) { }


  getAdhere(){
    this.statService.getStatListAdherent().subscribe((data:any)=>{
      this.adherents = data.adheres;
      this.Defaultadherents = this.adherents;
      console.log('this.adherents=', this.adherents);
      
      // this.avatarUrl = this.adherents.avatar ?  API_URL +'/'+ this.adhesion.adherent.avatar : this.avatarUrl ;
      // console.log(' this.avatarUrl',  this.avatarUrl);
    })
  }

  // tslint:disable-next-line:align
  // tslint:disable-next-line:no-unused-expression
  // tslint:disable-next-line:align
  onloaderdateFilter(eventData: {data: {mois: null, annee: null}}) {
    console.log('onloaderdateFilter', eventData.data);
    this.datefilter = eventData.data;
    if(this.datefilter.mois !=null && this.datefilter?.annee != null ){
      console.log('date?.mois', this.datefilter?.mois);
      console.log('date?.annee', this.datefilter?.annee);
      this.loader.active = true;
      this.statService.getStatListByDate(this.datefilter?.mois, this.datefilter?.annee).subscribe((data: any ) => {

        if( data.status === 200 ) {
          this.adherents = data.adheres;
          this.Defaultadherents = this.adherents;
          console.log('this.adherents filtre=', this.adherents);
          console.log('data.statist.annee=', data.statist.annee);
          console.log('data.statist.mois=', data.statist.mois);
          this.statTotalAdh = this.adherents.length.toString();
          let MoisAdh = FormatDate.months;
          MoisAdh =  MoisAdh.filter(item=> item.date.toString() === data.statist.mois);

          console.log('MoisAdh=', MoisAdh);
          this.statAnneAdh = data.statist.annee;
          this.statMoisAdh = MoisAdh[0].title;
          this.loader.active = false;
        }

      }) 
    }
  }
  onloaderdateItemFilter(eventData: {data: object}){
    console.log('onloaderdateItemFilter', eventData.data);
  }
  
navigateTo(link, param){
  this.router.navigate([`${link}/${param}`]);
}
 getLibelleMois(libelle){
   this.libelleMois = libelle;
 }
  ngOnInit(): void {

    this.filtreGroup = this.fb.group({
        mois: null,
        produits: null,
        situation: null
    });
    this.getAdhere();
  }
// modal
  openModalTemplate(template: TemplateRef<any>, userData): void{
    // this.openModal(template);
    this.profilUserName = userData.adherent.nom +' '+ userData.adherent.prenom;
    this.profiluserPhone = userData.adherent.telephone;
    this.profiluserAvatar = userData.adherent.avatar;
    this.profilMatricule = userData.adherent.matricule;
    this.profilBeneficiaire = userData.beneficiaire;
    this.modalRef = this.modalService.show(template);
  }
  hideModalTemplate(){
    this.modalRef.hide();
  }

}
