import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProduitService } from 'src/app/core/services/produit.service';
import { GroupeProduitModel } from 'src/app/shared/models/groupeproduit';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-produit',
  templateUrl: './produit.component.html',
  styleUrls: ['./produit.component.scss']
})
export class ProduitComponent implements OnInit {

  modalRef: BsModalRef;

  private groupeProduit: GroupeProduitModel[];
  public formGroupeProduit ;
  private DefaultgroupeProduit: GroupeProduitModel[];
  loader = {active: false};
  searchValue: string;
  private q = '';
  listCategorie: GroupeProduitModel[];
  idCategorie: string;

    // tslint:disable-next-line:no-shadowed-variable
    constructor(private modalService: BsModalService, private produitService: ProduitService, private fb: FormBuilder,
                private toastr: ToastrService ) { }

 
getgroupeProduit() {
  this.produitService.getGroupeProduit().subscribe((data: any) => {
      this.groupeProduit = data.GroupeProduits;

      console.log('this.groupeProduit=', this.groupeProduit);
      this.DefaultgroupeProduit = this.groupeProduit;
      console.log('this.DefaultgroupeProduit=', this.DefaultgroupeProduit);
  });

} 
  search() {
    //  console.log('rows', rows);
   // this.q = txt;
    console.log('q=', this.q);
    const columns =  this.DefaultgroupeProduit[0] && Object.keys( this.DefaultgroupeProduit[0]);
   // let testRows = [];

    // tslint:disable-next-line:max-line-length
    this.groupeProduit = this.DefaultgroupeProduit.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
     ));
    //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
   /*  console.log(' testRows',testRows); */
    console.log(' this.groupeProduit=', this.groupeProduit);
   // return testRows;
 }
  ajouterGroupeProduit(): void {
       // Copy the form values over the Medicament object values
       const groupe = Object.assign({}, this.formGroupeProduit.value);
       console.log('groupeProduit ', groupe);
       this.loader.active = true;
       this.produitService.addGroupeProduit(groupe).subscribe((res: any) => {
           console.log('reponse', res);
           if (res.status === 200) {
            console.log('res.status === 200');
            this.loader.active = false;
            this.modalRef.hide();
            this.annulerFormData();
            this.showSuccess();
            this.groupeProduit  = res.GroupeProduits;

            console.log('groupeProduit=', this.groupeProduit);
            this.DefaultgroupeProduit= this.groupeProduit;
            console.log('this.DefaultgroupeProduit=', this.DefaultgroupeProduit);
            }
       });

  }

  annulerFormData(): void {
    this.formGroupeProduit.reset();
  }

ngOnInit(): void {

  this.getgroupeProduit();

  
  // form validateur
  this.formGroupeProduit = this.fb.group({
    nom: ['', [Validators.required, Validators.minLength(2)]],
    
  });
}

editionGroupeProduit(item,template: TemplateRef<any>): void {
  console.log(item);
  this.idCategorie = item._id;
  this.formGroupeProduit.get('groupeProduit').setValue(item.groupeProduit);
  this.modalRef = this.modalService.show(template);

} 


modifierCategorie(): void {
  // Copy the form values over the Medicament object values
  const modifcategorie = Object.assign({}, this.formGroupeProduit.value);
  console.log('Categorie ', modifcategorie);
  this.loader.active = true;
/*   this.ActeService.editerCategorie(modifcategorie,this.idCategorie).subscribe((res: any) => {
      console.log('reponse', res);
      if (res.status === 200) {
       console.log('res.status === 200');
       this.listCategorie = [];
       this.listCategorie = res.groupeProduit;
       this.loader.active = false;
       this.modalRef.hide();
       this.annulerFormData();
       this.showSuccess();
    }
}); */

}

supprimCategorie(id): void {
  // Copy the form values over the Medicament object values
 
  console.log('id Catégories ', id);
  //this.loader.active = true;

  Swal.fire({
    title: 'Voulez-vous supprimer?',
    text: 'Vous ne pourrez plus récupérer ce fichier!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Oui, supprime-le!',
    cancelButtonText: 'Non, gardez-le'
  }).then((result) => {
    if (result.value) {

 
      this.produitService.deleteGroupeProduit(id).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.groupeProduit = res.groupeProduit;
         console.log('this.groupeProduit',this.groupeProduit);
         //this.loader.active = false;
         Swal.fire(
          'Supprimer!',
          'La catégorie a été supprimé.',
          'success'
        ) 

      }
  });

     
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })

}



openModal(template: TemplateRef<any>) {
this.modalRef = this.modalService.show(template);
}
/* hideModal(template: TemplateRef<any>) {
this.modalRef.hide();
} */
showSuccess() {
this.toastr.success('ajouter avec succes!',  'Ajout d\'une Catégorie');
}

}
