import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-form-centre',
  templateUrl: './form-centre.component.html',
  styleUrls: ['./form-centre.component.scss']
})
export class FormCentreComponent implements OnInit,  OnChanges {
  @Input() selectedtypeUser: string = '';
  @Output()loaderCreated = new EventEmitter<{ active: boolean }>();
  @Output()notif = new EventEmitter<{ status: string }>();
  role = [
    {
    nom: 'Agent',
    value: 'USER_AGENT'
  },
  {
    nom: 'Adhérent',
    value: 'USER_ADHERENT'
  }
];
typeUser = [
  {
    nom: 'Structure',
    value: 'structure'
  },
  
  {
  nom: 'Adhérent',
  value: 'adherent'
},
{
  nom: 'Centre santé',
  value: 'centre'
},
{
  nom: 'Pharmacie',
  value: 'pharma'
}
];
  listeCentreSante = null;
  public formCentre = null;
  public stepForm = 1 ;
  loader = {active: false};
  submitted = false;
  checkboxes: Array<any> = [
      {
      name: 'CNI',
      value: 'CNI',
      checked: false
    }, {
      name: 'Passport',
      value: 'Passport',
      checked: false
    }, {
      name: 'Autre',
      value: 'Autre',
      checked: false
    }
];
piecidentcheck = '';
//selectedtypeUser: string;

  constructor(private userServ: UserService, private fb: FormBuilder,
              private toastr: ToastrService) { }

  ajouterUser(): void {
    this.submitted = true;
    if (this.formCentre.valid) {
      const infoUser = Object.assign({}, this.formCentre.value);
      console.log('infoUser ', infoUser);
      this.loader.active = true;
      this.userServ.addUser(infoUser).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.submitted = false;
         this.loader.active = false;

         this.annulerFormData();
         this.showSuccess();

        }
      });


    }

  }



annulerFormData(): void {
  this.formCentre.reset();
}

precedent() {
  this.stepForm--;
}


onCheckboxChange(e) {

  if (e.target.checked) {
    console.log('onCheckboxChange', e.target.value);

    this.checkboxes.forEach((item, index) => {
      if (item.value !== e.target.value) {
        // tslint:disable-next-line:no-unused-expression
        item.checked = false;

        // console.log('checkboxes item', item.value);
        // console.log('checkboxes', this.checkboxes);
      } else {
        item.checked = true;
        this.piecidentcheck = e.target.value;
      }

    });
  }

}

  ngOnInit(): void {

    this.formCentre= this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      type_user: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
        userName: ['' , [Validators.required, Validators.minLength(2)]],
      password: ['', [Validators.required]],
      role: ['', [Validators.required, Validators.minLength(2)]],
  });



  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (changes.selectedtypeUser) {
      const nouvelleValeur = changes.selectedtypeUser.currentValue;
      const ancienneValeur = changes.selectedtypeUser.previousValue;

      // Traitez les changements de maPropriete ici
      console.log('Ma propriété a été modifiée. Nouvelle valeur :', nouvelleValeur);
      console.log('Ancienne valeur :', ancienneValeur);
      this.formCentre.get('type_user').value = changes.selectedtypeUser.currentValue;
      console.log('this.formCentre.get(type_user).value =', this.formCentre.get('type_user').value);
    }
  }

  showSuccess() {
    this.toastr.success('Ajout d\'un utilisateur. Voir la liste des utilisateurs', 'Ajouter avec succes!');
  }

}
