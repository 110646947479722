import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { AdherentModel } from 'src/app/shared/models/adherent';

import { select, Store } from '@ngrx/store';
import * as  fromRoot from '../../../../../core/store';
import * as fromAdhesions from '../../../../../core/store/adhesion'


@Component({
  selector: 'app-edit-adherent',
  templateUrl: './edit-adherent.component.html',
  styleUrls: ['./edit-adherent.component.scss']
})
export class EditAdherentComponent implements OnInit {

 
  private adherent: AdherentModel;
  private listAdherents: Array<any>;
  public formAdherent = {
    info_perso: null,
    piece_ident: null,
    situation: null
  } ;
  public stepForm = 1 ;
  loader = {active: false};
  submitted = false;
  checkboxes: Array<any> = [
      {
      name: 'CNI',
      value: 'CNI',
      checked: false
    }, {
      name: 'Passport',
      value: 'Passport',
      checked: false
    }, {
      name: 'Autre',
      value: 'Autre',
      checked: false
    }
];
piecidentcheck = '';
dateEtabliMatch = '' ;
dateNaissMatch = '';
stepperStatus = {
  info_perso: '',
  piece_ident: 'stepper--horizontal--disabled',
    situation: 'stepper--horizontal--disabled'
};
  idAdherent: string;
  constructor(private route: ActivatedRoute, private adherServ: AdherentService, private fb: FormBuilder,
              private toastr: ToastrService,private store: Store<fromRoot.State>,private router: Router) {

               }


               getAdherent(id) {
                this.adherServ.getAdherent().subscribe((data: any) => {
                  this.listAdherents = data.adherents;
                  console.log('this.listAdherents=',this.listAdherents);
                  this.adherent = this.listAdherents.find(item=>item._id ==  this.idAdherent )
                  console.log('this.adherent',this.adherent);
                  this.formAdherent.info_perso.controls.nom.setValue(this.adherent?.nom)
                  this.formAdherent.info_perso.controls.prenom.setValue(this.adherent?.prenom)
                  this.formAdherent.info_perso.controls.telephone.setValue(this.adherent?.telephone)
                  this.formAdherent.info_perso.controls.email.setValue(this.adherent?.email)
                  this.formAdherent.info_perso.controls.dateNaissance.setValue(
                    this.adherent.hasOwnProperty('dateNaissance') ? new Date(this.adherent?.dateNaissance)  :'' )
                 // this.formAdherent.info_perso.controls.dateEtabli.setValue()
                  this.formAdherent.info_perso.controls.lieuNaissance.setValue(
                    this.adherent.hasOwnProperty('lieuNaissance') ? this.adherent?.lieuNaissance :'' )
                  this.formAdherent.piece_ident.controls.lieuEtabli.setValue(this.adherent?.lieuEtabli)
                  this.formAdherent.piece_ident.controls.numeroPieceIdnt.setValue(this.adherent?.numeroPieceIdnt)
                  this.formAdherent.piece_ident.controls.dateEtabli.setValue(
                    this.adherent.hasOwnProperty('dateEtabli') ? new Date(this.adherent?.dateEtabli) : '')
                    console.log('this.adherent?.telephone', this.adherent?.telephone)
                    console.log('this.adherent.dateNaissance',  this.adherent?.dateNaissance)
                    console.log('this.adherent.pieceIdnt',  this.adherent?.pieceIdnt)
                });
               
              
              }

  etapeUne(): void {
    console.log('this.adherent?.telephone', this.adherent?.telephone)
    console.log('this.adherent.dateNaissance',  this.adherent?.dateNaissance)
    console.log('this.adherent.pieceIdnt',  this.adherent?.pieceIdnt)
    this.formAdherent.piece_ident.controls.pieceIdnt.setValue(this.adherent?.pieceIdnt)
    this.formAdherent.piece_ident.controls.civilite.setValue(this.adherent?.civilite)
    this.submitted = true;
    if (this.formAdherent.info_perso.valid) {
      const adherentinfoPerso = Object.assign({}, this.formAdherent.info_perso.value);
      adherentinfoPerso.dateNaissance = this.dateNaissMatch;
      console.log('adherentinfoPerso ', adherentinfoPerso);
      this.stepForm++;
      this.stepperStatus.info_perso = 'stepper--horizontal--valide';
      this.stepperStatus.piece_ident = '';
      this.submitted = false;

    }

  }
  etapeDeux(): void {
    this.formAdherent.situation.controls.situationMat.setValue(this.adherent.situationMat)
    this.formAdherent.situation.controls.emploi.setValue(this.adherent.emploi)
    this.formAdherent.situation.controls.structure.setValue(this.adherent.structure)
    this.formAdherent.situation.controls.localite.setValue(this.adherent.localite)
    this.submitted = true;
    if (this.formAdherent.piece_ident.valid) {
      const adherentPiecIdent = Object.assign({}, this.formAdherent.piece_ident.value);
      adherentPiecIdent.pieceIdnt =  this.piecidentcheck;
      adherentPiecIdent.dateEtabli =  this.dateEtabliMatch;
      console.log('adherentPiecIdent ', adherentPiecIdent);
      this.stepForm++;
      this.stepperStatus.piece_ident = 'stepper--horizontal--valide';
      this.stepperStatus.situation = '';
      this.submitted = false;

    }


  }
  etapeTrois(): void {

    this.submitted = true;
    if (this.formAdherent.situation.valid) {
      const adherent = Object.assign({}, this.formAdherent.info_perso.value,
        this.formAdherent.piece_ident.value, this.formAdherent.situation.value);
      adherent.dateNaissance = this.dateNaissMatch;
      //adherent.pieceIdnt =  this.piecidentcheck;
      adherent.dateEtabli =  this.dateEtabliMatch;
      console.log('adherent ', adherent);
      this.stepperStatus.situation = 'stepper--horizontal--valide';
      this.loader.active = true;
      this.adherServ.modifierAdherent(this.idAdherent,adherent).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
         this.submitted = false;
         this.stepForm = 1;
         this.stepperStatus.info_perso = '';
         this.stepperStatus.piece_ident = 'stepper--horizontal--disabled';
         this.stepperStatus.situation = 'stepper--horizontal--disabled';
        // this.annulerFormData();
        this.toastr.success('Edition de l\'adhérent. ', 'Editer avec succes!');

                     }
      },error=>{
        this.loader.active = false;
        this.submitted = false;
        console.log('error',error)
        console.log('error message',error.message)
        //this.toastr.error()
      });

    }


  }
  preAjouterAdherent(): void {
    // Copy the form values over the patient object values
    // const adherent = Object.assign({}, this.formAdherent.value);
    // console.log('adherent ', adherent);
    // this.loader.active = true;
    // this.adherServ.preEnrAdher(adherent).subscribe((res: any) => {
    //     console.log('reponse', res);
    //     if (res.status === 200) {
    //      console.log('res.status === 200');
    //      this.loader.active = false;

    //      this.annulerFormData();
    //      this.showSuccess();

    //                  }
    // });

}

annulerFormData(): void {
 // this.formAdherent.reset();
 this.formAdherent.info_perso.reset() ;
 this.formAdherent.piece_ident.reset() ;
 this.formAdherent.situation.reset() ;
}
annulerFormDataInfoperso() {

  this.formAdherent.info_perso.reset() ;
}
precedent() {
  this.stepForm--;
}
onDatepickerChange(e, typeDate) {

   const datePick = e ;
   if ( typeDate === 'dateNaiss') {
    this.dateNaissMatch =  FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateNaissMatch', this.dateNaissMatch );
  } else if (typeDate === 'dateEtabli') {

    console.log('onDatepickerChange', e);
    // this.formAdherent.piece_ident.get('dateEtabli').patchValue(FormatDate.getFormatDate('yy-mm-dd', new Date()));
    this.dateEtabliMatch = FormatDate.getFormatDate('yy-mm-dd', datePick);
    console.log('this.dateEtabliMatch', this.dateEtabliMatch);
  }


}

onCheckboxChange(value: any,e) {
  console.log('onCheckboxChange value', value);
  console.log('onCheckboxChange', e.target.value);
  console.log('e.target.checked', e.target.checked);
  this.formAdherent.piece_ident.controls.pieceIdnt.setValue(value)
 /*  if (e.target.checked) {
    console.log('onCheckboxChange', e.target.value);

    this.checkboxes.forEach((item, index) => {
      if (item.value !== e.target.value) {
        // tslint:disable-next-line:no-unused-expression
        item.checked = false;

        // console.log('checkboxes item', item.value);
        // console.log('checkboxes', this.checkboxes);
      } else {
        item.checked = true;
        //this.piecidentcheck = e.target.value;
      }

    });
  } */

}

  ngOnInit(): void {

    this.formAdherent.info_perso = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
      email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      dateNaissance: ['', [Validators.required]],
      lieuNaissance: ['', [Validators.required, Validators.minLength(2)]],
  });

    this.formAdherent.piece_ident = this.fb.group({
      pieceIdnt: ['' , [Validators.required]],
      numeroPieceIdnt: ['', [Validators.required, Validators.minLength(2)]],
      dateEtabli: ['', [Validators.required, Validators.minLength(2)]],
      lieuEtabli: ['', [Validators.required, Validators.minLength(2)]],
      civilite: ['', [Validators.required]],
    /* genre: ['', [Validators.required]], */

});

    this.formAdherent.situation = this.fb.group({
      situationMat: ['', [Validators.required, Validators.minLength(2)]],
      emploi: ['', [Validators.required, Validators.minLength(2)]],
      structure: ['', [Validators.required, Validators.minLength(2)]],
      localite: ['', [Validators.required, Validators.minLength(2)]],
     /*  civilite: ['', [Validators.required]], */
/* genre: ['', [Validators.required]], */

});


// edition adherent
this.idAdherent = this.route.snapshot.params.id;
console.log('this.idAdherent',this.idAdherent)
    if (this.idAdherent) {
      this.store.pipe(select(state =>   state.adherents.entities )).subscribe((adherents:any) => {

        // Faire quelque chose avec les données des adhérents mises à jour
        console.log('adherents=',adherents)
         if(!adherents){
             this.getAdherent(this.idAdherent)
         }else{
        this.adherent = adherents.find(item=>item.slug ==  this.idAdherent )
          console.log('this.adherent .',this.adherent )
         
          this.formAdherent.info_perso.controls.nom.setValue(this.adherent?.nom)
          this.formAdherent.info_perso.controls.prenom.setValue(this.adherent?.prenom)
          this.formAdherent.info_perso.controls.telephone.setValue(this.adherent?.telephone)
          this.formAdherent.info_perso.controls.email.setValue(this.adherent?.email)
          this.formAdherent.info_perso.controls.dateNaissance.setValue(
            this.adherent.hasOwnProperty('dateNaissance') ? new Date(this.adherent?.dateNaissance)  :'' )
         // this.formAdherent.info_perso.controls.dateEtabli.setValue()
          this.formAdherent.info_perso.controls.lieuNaissance.setValue(
            this.adherent.hasOwnProperty('lieuNaissance') ? '' : this.adherent?.lieuNaissance)
          this.formAdherent.piece_ident.controls.lieuEtabli.setValue(this.adherent?.lieuEtabli)
          this.formAdherent.piece_ident.controls.numeroPieceIdnt.setValue(this.adherent?.numeroPieceIdnt)
          this.formAdherent.piece_ident.controls.dateEtabli.setValue(
            this.adherent.hasOwnProperty('dateEtabli') ? '' : new Date(this.adherent?.dateEtabli))
        
            console.log('this.adherent?.telephone', this.adherent?.telephone)
            console.log('this.adherent.dateNaissance',  this.adherent?.dateNaissance)
            console.log('this.adherent.pieceIdnt',  this.adherent?.pieceIdnt)
            this.formAdherent.piece_ident.controls.pieceIdnt.setValue(this.adherent?.pieceIdnt)
            this.formAdherent.piece_ident.controls.civilite.setValue(this.adherent?.civilite)
            this.formAdherent.situation.controls.situationMat.setValue(this.adherent.situationMat)
            this.formAdherent.situation.controls.emploi.setValue(this.adherent.emploi)
            this.formAdherent.situation.controls.structure.setValue(this.adherent.structure)
            this.formAdherent.situation.controls.localite.setValue(this.adherent.localite)
         }
        
      });;
      

}



  }

  showSuccess() {
    this.toastr.success('Ajout d\'un adhérent. Voir la liste des adhérents', 'Ajouter avec succes!');
  }

  navigateTo() {
    this.router.navigate([`adherents/liste-adherent`]);
   }


}
