import { Injectable } from '@angular/core';
import {Effect, Actions, ofType} from '@ngrx/effects';

import { Observable, of, zip  } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { VilleModel } from 'src/app/shared/models/ville.model';
import { LocalisationService } from '../../services/localisation.service';


import * as fromActions from './villes.actions';
type Action = fromActions.All;

@Injectable()
export class VilleEffects {

 constructor( private actions$: Actions, private localService: LocalisationService ) {}

 @Effect()
 add: Observable<Action> = this.actions$.pipe(
      ofType(fromActions.Types.ADD)
 );

 @Effect()
 read: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.Read>(fromActions.Types.READ),
    mergeMap(
        () => this.localService.getVille()
        .pipe(
            map((data: any) => new fromActions.ReadSuccess(data)),
            catchError(errror => of(new fromActions.ReadError(errror)))
        )
    )
 );
}
