import { Pipe, PipeTransform } from '@angular/core';
import { PatientModel } from '../models/patient.model';

@Pipe({
  name: 'searchitermfilter'
})
export class SearchitermfilterPipe implements PipeTransform {

  transform(Items: any[], searchValue: string): any[] {

    if (!Items || !searchValue) {

      return Items;

     }
    const columns =  Items[0] && Object.keys( Items[0]);

    return Items.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( searchValue ) > -1
    ));
  }

}
