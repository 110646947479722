import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {
  loading=false;
  formLogin = null;
  constructor( private service: LoginService, private fb: FormBuilder, private route: Router,
               private toastr: ToastrService   ) {

    if ( this.service.isLoggedIn()) {
      this.route.navigate(['/dashboard']);
      }

  }
  Login() {
     if (this.formLogin.valid) {
    console.log('formLogin', this.formLogin.value);
    this.loading =true;
    this.service.generateTokenAuth().subscribe((reponse: any)=>{
      console.log('reponse generateTokenAuth', reponse);
          if (reponse.status === 200 ){
               const authToken = reponse.data.data;
               console.log('tokauthToken',authToken)
              localStorage.setItem('authToken',authToken) ;
               
                   this.service.connexion(authToken,this.formLogin.value).subscribe((result: any) => {  

                    if ( result.status === 403) {
                      const message = result.message;
                      this.toastr.error(message, 'Echec connexion!');
                      this.loading =false;

                   } else if (result.status === 201 ) {
                    localStorage.removeItem('authToken')
                          console.log('result', result);
                          const accessToken = result.token;
                          const user = JSON.stringify(result.user) ;
                          console.log('access token', accessToken);
                          console.log('user', user);
                          localStorage.setItem('accessToken', accessToken);
                          localStorage.setItem('user_mugasci', user);
                          localStorage.setItem('mugasci-role', result.user.role);
                          this.toastr.success('Vous être connecté '+
                          result.user.nom +' '+ result.user.prenom,'Connexion.')
                          this.loading =false;

                          this.route.navigate(['']);
                         
                      }
                  },(error)=>{

                    this.loading =false;

                    console.log('error',error);
                    if(error.status==401){
                      this.toastr.error('vous n\'êtes pas autorisé','Connexion échoué',)
                     }else if (error.status==403){

                      this.toastr.error(error.error.message, 'Accès refusé');
                     }

                  })        
          }
    },(error)=>{
      this.loading =false;

      console.log('error',error);
       if(error.status==401){
        this.toastr.error('Connexion échoué','vous n\'êtes pas autorisé')
       }
    })
   
           }
  }
  ngOnInit(): void {

    this.formLogin =  this.fb.group({
      email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

}
