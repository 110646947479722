import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { LocalisationService } from 'src/app/core/services/localisation.service';
import { CommuneModel } from 'src/app/shared/models/commune.model';
import { VilleModel } from 'src/app/shared/models/ville.model';
import * as fromVilles from 'src/app/core/store/villes';
import * as fromRoot from 'src/app/core/store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-centre-sante',
  templateUrl: './add-centre-sante.component.html',
  styleUrls: ['./add-centre-sante.component.scss']
})
export class AddCentreSanteComponent implements OnInit {
  public formCentreSante = null;
  listVilles: VilleModel[] = []; // Liste des villes
  listCommunes: CommuneModel[] = []; // Liste des communes
  villes$: Observable<fromVilles.Villes[]> ;
  submitted = false;
  loader = {active: false};
  constructor(private localSrv: LocalisationService, private fb: FormBuilder,
              private toastr: ToastrService, private store: Store<fromRoot.State>,
              private router: Router) { }


   CreerCentre(): void {

    this.submitted = true;
    console.log('this.formCentreSante.valid', this.formCentreSante.valid);
    if (this.formCentreSante.valid) {
      const centre = Object.assign({}, this.formCentreSante.value);

      console.log('centre ', centre);

      this.loader.active = true;
      this.localSrv.addCentreSante(centre).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
         this.submitted = false;

         this.formCentreSante.reset() ;
         this.showSuccess();
         this.router.navigate([`liste-centre-sante`]);

                     }
      },
       (error) => {
        console.error('error addCentreSante', error);
            }

            ); 

          }// fin du if this.formCentreSante.valid

      }
  getVilles(): void {
        console.log('this.getVilles');
        this.localSrv.getVille().subscribe((data: any) => {
          if ( data.status === 200) {
            this.listVilles = data.villes;
            console.log('this.listVilles=', this.listVilles);
          }

        });
    }
  ngOnInit(): void {
    console.log('ajouter centre de santer');
    this.formCentreSante = this.fb.group({
      nomCentre: ['', [Validators.required, Validators.minLength(2)]],
      nomResponsable: ['', [Validators.required, Validators.minLength(2)]],
      prenomResponsable: ['', [Validators.required, Validators.minLength(2)]],
      fonction: ['', [Validators.required, Validators.minLength(2)]],
      idville: ['', [Validators.required, Validators.minLength(2)]],
      commune: ['', [Validators.required, Validators.minLength(2)]],
      etablissement:['', [Validators.required]],
      description:[''],
      // telephone: ['', [Validators.required, Validators.minLength(2),
      //   Validators.pattern(ValidationService.numberMobilDigit)]],
      // email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      situationGeo: [''/* , [Validators.required, Validators.minLength(2)] */],

  });

    this.getVilles();
   // this.villes$ =   this.store.pipe(select(fromVilles.));
   //  console.log('villes$', this.villes$);
   // this.store.dispatch(new fromVilles.Read());
  }

  onVilleChange(villeId: string) {
    console.log('villeId:=', villeId);
    // Effectuer une requête HTTP pour récupérer la liste des communes selon l'ID de la ville sélectionnée
    this.localSrv.getCommuneByVille(villeId).subscribe((data: any) => {
      if ( data.status === 200) {
        this.listCommunes = data.communes;
        console.log('this.listCommunes=', this.listCommunes);

      }
    });
  }

  showSuccess() {
    this.toastr.success('Ajout d\'un centre. Voir la liste des centres', 'Ajouter avec succes!');
  }

}
