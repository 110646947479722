import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { AdherentService } from 'src/app/core/services/adherent.service';
import { AdherentModel } from 'src/app/shared/models/adherent';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// STORE 

import { select, Store } from '@ngrx/store';
import * as  fromRoot from '../../../../../core/store';
import * as fromAdhesions from '../../../../../core/store/adhesion'


const API_URL = environment.backend_url;
@Component({
  selector: 'app-all-adherent',
  templateUrl: './all-adherent.component.html',
  styleUrls: ['./all-adherent.component.scss']
})
export class AllAdherentComponent implements OnInit {
  modalRef: BsModalRef;
  private adherents: AdherentModel[];
  private Defaultadherents: AdherentModel[];
  loader = {active: false};
  searchValue: string;
  private q = '';
  apiUrl = API_URL;
 // pagination
//  contentArray: string[] = new Array(50).fill('');
contentArray: string[] = new Array(10).fill('');
 returnedArray: string[];
 showBoundaryLinks: boolean = true;
 showDirectionLinks: boolean = true;

comments: Array<any> = [];
  constructor(private adherentService: AdherentService, private router: Router, 
              private modalService: BsModalService, private store: Store<fromRoot.State>) { }


  getAdherents() {
    this.adherentService.getAdherent().subscribe((data: any) => {
      this.adherents = data.adherents;
      this.Defaultadherents = this.adherents;
      console.log('this.adherents=', this.adherents);
      this.contentArray = new Array(this.adherents.length).fill('');
    });
  }
  search() {
    //  console.log('rows', rows);
   // this.q = txt;
    console.log('q=', this.q);
    const columns =  this.Defaultadherents[0] && Object.keys( this.Defaultadherents[0]);

   // let testRows = [];

    // tslint:disable-next-line:max-line-length
    this.adherents = this.Defaultadherents.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
     ));
    //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
   /*  console.log(' testRows',testRows); */
    console.log(' this.adherents=', this.adherents);
   // return testRows;
 }

 navigate(param) {
  this.router.navigate([`adherent/profile/${param}`]);
 }

 linkEdition(param) {
 /*  const adh = this.adherents.filter(item => item.slug === param);
  console.log(' adherent', adh);
  console.log(JSON.stringify(adh));
  localStorage.setItem('adherent', JSON.stringify(adh)); */
  this.router.navigate([`adherent/${param}`]);
 }
  ngOnInit(): void {
    this.getAdherents();
    const adherentInfos = this.store.select(store => console.log('store', store) );

    this.contentArray = this.contentArray.map((v: string, i: number) => {
      return 'Line ' + (i + 1);
   });
    this.returnedArray = this.contentArray.slice(0, 5);
  }

 // pagination
 pageChanged(event: PageChangedEvent): void {
  console.log('event pagination', event);
  const startItem = (event.page - 1) * event.itemsPerPage;
  const endItem = event.page * event.itemsPerPage;
  this.returnedArray = this.contentArray.slice(startItem, endItem);
}

openModalComment(template: TemplateRef<any>,myComments){
  this.comments = myComments;
  this.modalRef = this.modalService.show(template);
}

hideModalTemplate(eventData: { data: any }){
  console.log('modal', eventData.data);
  if ( eventData.data === 'hide-modal') {
    this.modalRef.hide();
  }
}
openModalTemplate(template: TemplateRef<any>): void{
  // this.openModal(template);
  this.modalRef = this.modalService.show(template);
}

}
