import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { LocalisationService } from 'src/app/core/services/localisation.service';
import { CentreSanteModel } from 'src/app/shared/models/centre_sante.model';
import * as fromRoot from 'src/app/core/store';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { DocteurModel } from 'src/app/shared/models/docteur.model';
import { UserService } from 'src/app/core/services/user.service';
const API_URL = environment.backend_url;
@Component({
  selector: 'app-centre-sante-profile',
  templateUrl: './centre-sante-profile.component.html',
  styleUrls: ['./centre-sante-profile.component.scss']
})
export class CentreSanteProfileComponent implements OnInit {
  centreSante = {} ;
  idCentreSante: string = '';
  loader = {active: false};
  formAgentSante: any;
  formUserAgentSante: any;
  avatarUrl = null;
  medecins: DocteurModel[] ;
  basicTab: boolean = true;
  addMedecinTab: boolean = false;
  generalTab: boolean = false;
  submitted: boolean = false;
  role = [
    {
    nom: 'Utilisateur',
    value: 'USER_CENTRE'
  },
  {
    nom: 'Administrateur',
    value: 'ADMIN_CENTRE'
  }
];
  constructor(private route: ActivatedRoute , private centreSrv: LocalisationService,
              private userSrv: UserService, private fb: FormBuilder,
              private toastr: ToastrService, private store: Store<fromRoot.State>,
              private router: Router) {

                const id: string = this.route.snapshot.params.id;
                if (id) {
                  this.idCentreSante = id;
                  console.log('this.idCentreSante = ', this.idCentreSante);
                  this.getProfile(this.idCentreSante);

                } else {

                 this.router.navigate([`liste-centre-sante`]);
                }
               }


               getProfile(id) {
                this.centreSrv.getProfileCentreSante(id).subscribe((res: any) => {
                        console.log('reponse', res);
                        if (res.status === 200) {
                      console.log('res.status === 200');
                      this.centreSante = res.centreSante;
                      console.log('this.centreSante', this.centreSante);
                      this.medecins = res.agentSantes;
                      console.log('this.medecins', this.medecins);
                     /*  this.idAdherModal = this.adherent._id;
                      this.adhesion = res.profileAdherent.bulletin_adhesion;
                      console.log(' this.adhesion',  this.adhesion);
                      this.montantAdh = this.adhesion.montantAdhesion;
                      console.log(' this.adhesion.adherent.avatar', this.adhesion.adherent.avatar);
                      this.avatarUrl = this.adhesion.adherent.avatar ?  API_URL +'/'+ this.adhesion.adherent.avatar : this.avatarUrl ;
                      console.log(' this.avatarUrl',  this.avatarUrl); */
                     }
                });
               }

               AjoutMedecin(){
                this.submitted = true;
                console.log('this.formAgentSante', this.formAgentSante);
                console.log('this.formAgentSante valid', this.formAgentSante.valid);
                const medecin = Object.assign({}, this.formAgentSante.value);
               // medecin.centreSante = this.idCentreSante ;
                console.log('medecin', medecin);
                if (this.formAgentSante.valid) {

                  this.loader.active = true;
                  this.centreSrv.addMedecinCentreSante(medecin).subscribe((res: any) => {
              
                    console.log('reponse', res);
                    if (res.status === 200) {
                     console.log('res.status === 200');
                     this.loader.active = false;
                     this.medecins = res.AgentSante;
                     // this.socket.emit('update_adhesion', res.bulletin_adhesion);
                     this.onTab('1');
                     this.annulerFormData(this.formAgentSante);
                     this.showSuccess('Ajouter avec succes.','Medecin');
                  }
                }, (error) => {
                  console.error('error addMedecinCentreSante', error);
                  this.loader.active = false;
                      }

                );
                }

               }
               AjoutUserAgentSante(){
                this.submitted = true;
                console.log('this.formUserAgentSante', this.formUserAgentSante);
                console.log('this.formUserAgentSante valid', this.formUserAgentSante.valid);
                const UserAgentSante = Object.assign({}, this.formUserAgentSante.value);
               // medecin.centreSante = this.idCentreSante ;
                console.log('UserAgentSante', UserAgentSante);
                if (this.formUserAgentSante.valid) {

                  this.loader.active = true;
                  this.userSrv.addUserAgentSante(UserAgentSante).subscribe((res: any) => {

                    console.log('reponse', res);
                    if (res.status === 200) {
                     console.log('res.status === 200');
                     this.loader.active = false;
                     this.onTab('1');
                     this.annulerFormData(this.formUserAgentSante);
                     // Définir à nouveau le validateur sur le contrôle 'email'
                     this.formUserAgentSante.get('email').setValidators([Validators.required, Validators.pattern(ValidationService.emailRegex)]);

                     this.showSuccess(res.message,'Création de compte');
                  }
                }, (error) => {
                          console.error('error add user agent Sante', error);
                         this.loader.active = false;
                         this.toastr.error(error.message, '');

                      }

                );
                }

               }

  onTab(number) {
    this.basicTab = false;
    this.addMedecinTab = false;
    this.generalTab = false;

    if (number == '1') {
      this.basicTab = true;
    } else if (number == '2') {
      this.addMedecinTab = true;
    } else if (number == '3') {
      this.generalTab = true;
    }
  }

  ngOnInit(): void {
    console.log('medecins', this.medecins);
    this.formAgentSante = this.fb.group({
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
       telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
      // email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      centreSante: [this.idCentreSante, [Validators.required]],
      fonction: ['', [Validators.required, Validators.minLength(2)]],
    });
// creation d'un compte
    this.formUserAgentSante =  this.fb.group({
      userName: ['', [Validators.required, Validators.minLength(2)]],
      nom: ['', [Validators.required, Validators.minLength(2)]],
      prenom: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      confirmPassword: ['', [Validators.required]],
       telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
       email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
       role: ['', [Validators.required, Validators.minLength(2)]],
      centreSante: [this.idCentreSante, [Validators.required]],
      type_user: ['centre', [Validators.required, Validators.minLength(2)]],
    }, { validator: ValidationService.passwordMatchValidator });
  }

  annulerFormData(form): void {
    form.reset();
  }


    showSuccess(message,titre) {

    this.toastr.success(message, titre);
    }


}
