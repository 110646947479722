import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-stat-total-user',
  templateUrl: './stat-total-user.component.html',
  styleUrls: ['./stat-total-user.component.scss']
})
export class StatTotalUserComponent implements OnInit {
  @ViewChild('js-pointer') jsPointer: ElementRef;
  public CONTAINER_PADDING_HALF = '0.5em';
  isNavPointer = [true, false];
  constructor(private renderer: Renderer2) { }

  movePointer(index,isboolean) {
   this.isNavPointer[index] = isboolean;
   for ( let i = 0; this.isNavPointer.length  > i ;  i++ ) {

    if ( index !== i ) {
      this.isNavPointer[i] = false;
    }
   }
    //const order = e.currentTarget.dataset.order;
    // tslint:disable-next-line:max-line-length
   // this.renderer.setStyle(this.jsPointer.nativeElement, 'transform', `translate3d(` + `${order}` + `0,0)`);
   // this.jsPointer.nativeElement
   // pointer.style.transform = "translate3d("+order+",0,0)"
  }

  ngOnInit(): void {
  }

}
