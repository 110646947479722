import { Injectable } from '@angular/core';
import {Effect, Actions, ofType} from '@ngrx/effects';

import { Observable, of, zip  } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Adhesions } from './adhesion.models';
import { AdherentService } from '../../services/adherent.service';


import * as fromActions from './adhesion.actions';
type Action = fromActions.All;

@Injectable()
export class AdhesionEffects {

 constructor( private actions$: Actions, private adhService: AdherentService ) {}

 @Effect()
 add: Observable<Action> = this.actions$.pipe(
      ofType(fromActions.Types.ADD)
 );

 @Effect()
 read: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.Read>(fromActions.Types.READ),
    mergeMap(action =>
      this.adhService.getBulletinAdhesionStore(action.data).pipe(
        map((data: any) => new fromActions.ReadSuccess(data)),
        catchError(error => of(new fromActions.ReadError(error)))
      )
    )
  );

/*   @Effect()
  readSuccess: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.ReadSuccess>(fromActions.Types.READ_SUCCESS),
    mergeMap(action =>
      this.adhService.getBulletinAdhesionStore(action.id).pipe(
        map((data: any) => new fromActions.ReadSuccess(data)),
        catchError(error => of(new fromActions.ReadError(error)))
      )
    )
  ); */
}
