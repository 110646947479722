import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupeProduitModel } from 'src/app/shared/models/groupeproduit';
import { ProduitModel } from 'src/app/shared/models/produit';
import { environment } from 'src/environments/environment';

const API_URL = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
// Http Options
httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

constructor(private httpClient: HttpClient) { }




// Liste produit
getProduit(): Observable<ProduitModel[]> {

  return this.httpClient.get<ProduitModel[]>(`${API_URL}/produit/list`);
}

// enregistrer produit
addProduit(produit: ProduitModel): Observable<ProduitModel> {

return this.httpClient.post<ProduitModel>(`${API_URL}/produit/enregistrer`, produit, this.httpOptions
);
}

  // modifier modifier Acte
  editerProduit(produit: ProduitModel, idProduit: string): Observable<ProduitModel> {

    return this.httpClient.put<ProduitModel>(`${API_URL}/produit/modifier/${idProduit}`, produit, this.httpOptions);
  
  }
// delete adherent
deleteProduit(id){
  return this.httpClient.get<ProduitModel>(`${API_URL}/produit/supprimer/${id}`);
}
// pre enregistrer adherent
addGroupeProduit(produit: GroupeProduitModel): Observable<ProduitModel> {

  return this.httpClient.post<ProduitModel>(`${API_URL}/groupe/produit/enregistrer`, produit, this.httpOptions
  );
  }

  // Liste adherent
  // tslint:disable-next-line:adjacent-overload-signatures
  getGroupeProduit(): Observable<GroupeProduitModel[]> {

    return this.httpClient.get<GroupeProduitModel[]>(`${API_URL}/groupe/produit/list`);
  }

  deleteGroupeProduit(id){
    return this.httpClient.get<GroupeProduitModel>(`${API_URL}/groupe/produit/supprimer/${id}`);
  }
}
