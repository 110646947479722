import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pop-up-social-notification',
  templateUrl: './pop-up-social-notification.component.html',
  styleUrls: ['./pop-up-social-notification.component.scss']
})
export class PopUpSocialNotificationComponent implements OnInit {
  @Input() profilName: string;
  constructor() { }

  ngOnInit(): void {
  }

}
