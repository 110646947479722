import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { AdherentService } from 'src/app/core/services/adherent.service';
import { ProduitService } from 'src/app/core/services/produit.service';
import { AdherentModel } from 'src/app/shared/models/adherent';

// STORE 

import { select, Store } from '@ngrx/store';
import * as  fromRoot from '../../../../../core/store';
import * as fromAdhesions from '../../../../../core/store/adhesion'
import * as fromAdherents from '../../../../../core/store/adherents'
import { getAdherents } from 'src/app/core/store/adherents/adherents.selectors';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/core/patterns/ValidationService';
import { FormatDate } from 'src/app/core/patterns/FormatDate';




const API_URL = environment.backend_url;

@Component({
  selector: 'app-beneficiaire-profile',
  templateUrl: './beneficiaire-profile.component.html',
  styleUrls: ['./beneficiaire-profile.component.scss']
})
export class BeneficiaireProfileComponent implements OnInit {


  idAdherModal ='';
  modalRef: BsModalRef;
  basicTab: boolean = true;
  accountTab: boolean;
  generalTab: boolean;
  isFull: boolean;
  isFull1: boolean;
  isFull2: boolean;
  loader = {active: false};
  submitted = false;
  produitValue: any;
  subscription: Subscription;
  checkboxGroup: FormGroup;
  idAdherent = null;
  idBeneficiaire= null;
  checkSexe;
  dateNaissMatch = '';
  dateEtabliMatch = '' ;
  formBeneficiaires:FormGroup;
  private beneficiaire: AdherentModel ;
 adhesion$: Observable<fromAdhesions.Adhesions>;
  avatarUrl: any;
  montantAdh: any;

  constructor(route: ActivatedRoute, private router: Router , private adherServ: AdherentService, private fb: FormBuilder,
    private toastr: ToastrService, private produitService: ProduitService, private modalService: BsModalService,private store: Store<fromRoot.State>) {
      this.idAdherent = route.snapshot.params.id;
      console.log('this.idAdherent',this.idAdherent)
      // identifiant du beneficiaire
      this.idBeneficiaire = route.snapshot.params.id_ben;
      console.log('this.idBeneficiaire.',this.idBeneficiaire)
      if (this.idAdherent && this.idBeneficiaire) {
       

        this.store.pipe(select(state =>   state.adhesions.entities )).subscribe((adhesion:any) => {
      
          // Faire quelque chose avec les données des adhérents mises à jour
          console.log('adhesion=',adhesion)
           if(!adhesion){
               this.getProfile(this.idAdherent)
           }else{
            this.beneficiaire = adhesion.beneficiaire.find(item=>item.slug ==  this.idBeneficiaire )
            console.log('this.beneficiaire .',this.beneficiaire )
           }
          
        });;
       
          // const agent  = localStorage.getItem('user_mugasci');
          // this.infoUser = JSON.parse(agent);
         //  console.log('infoUser', this.infoUser);
           //this.idAgent = this.getuserAgent(this.infoUser._id);
          // this.getProfile(this.idAdherent);
         //  this.getProduit();

         } else {

          this.router.navigate(['/adherents/liste-adherent']);

         }
}


getProfile(slug) {
  console.log('slug', slug);

 this.adherServ.getProfileAdherent(slug).subscribe((res: any) => {
         console.log('reponse', res);
         if (res.status === 200) {
       console.log('res.status === 200');
      // this.adherent = res.profileAdherent.adherent;
      // console.log('this.adherent', this.adherent);
       //this.idAdherModal = this.adherent._id;

       const beneficiaires = res['profileAdherent']['bulletin_adhesion']['beneficiaire'];
       console.log('beneficiaires', beneficiaires);
       this.beneficiaire =  beneficiaires.find(item=>item.slug ==  this.idBeneficiaire )
       console.log(' this.beneficiaire',  this.beneficiaire);
      /* this.formBeneficiaires.controls.dateNaissance.setValue(
        this.beneficiaire.hasOwnProperty('dateNaissance')? this.beneficiaire.dateNaissance :'');*/
         this.formBeneficiaires.controls.sexe.setValue(this.beneficiaire.sexe)   
         this.formBeneficiaires.controls.nom.setValue(this.beneficiaire.nom)
         this.formBeneficiaires.controls.prenom.setValue(this.beneficiaire.prenom)
         this.formBeneficiaires.controls.telephone.setValue(this.beneficiaire.telephone)
         this.avatarUrl =  this.beneficiaire.avatar ? API_URL + '/'+this.beneficiaire.avatar : ''
        // this.store.dispatch(new fromAdhesions.Read(this.adhesion))
       //this.montantAdh = this.adhesion != null ? this.adhesion.montantAdhesion : null;
      // console.log(' this.adhesion.adherent.avatar', this.adhesion);
      // this.avatarUrl = this.beneficiaire != null ? (this.adhesion.adherent.avatar ?  API_URL +'/'+ this.adhesion.adherent.avatar : this.avatarUrl) : null;
      //console.log(' this.avatarUrl',  this.avatarUrl);
      }
 },(error)=>{
   
     console.log('error getprofile',error);
     this.toastr.error(error.error.message, 'Echec!');
 }); 


}
// ajouterBeneficiaire
editerBeneficiaire(){
  this.submitted = true;
  console.log('this.formBeneficiaires.value', this.formBeneficiaires.value);
  console.log('this.formBeneficiaires', this.formBeneficiaires);
  if (this.formBeneficiaires.valid) {
    const data = Object.assign({}, this.formBeneficiaires.value);
    console.log('data beneficiaire ', data);
    data.dateNaissance = this.dateEtabliMatch;
    console.log('data beneficiaire ', data);
      this.loader.active = true;
  this.adherServ.editBeneficiaire(data).subscribe((res: any) => {
    console.log('reponse', res);
    if (res.status === 200) {
  console.log('res.status === 200');
 // this.adherent = res.profileAdherent.adherent;
 // console.log('this.adherent', this.adherent);
  //this.idAdherModal = this.adherent._id;

  this.beneficiaire = res['beneficiaire'];

  console.log(' this.beneficiaire',  this.beneficiaire);
 /*  this.formBeneficiaires.controls.dateNaissance.setValue(
   this.beneficiaire.hasOwnProperty('dateNaissance')? this.beneficiaire.dateNaissance :''); */
  this.formBeneficiaires.controls.sexe.setValue(this.beneficiaire.sexe)   
   this.formBeneficiaires.controls.nom.setValue(this.beneficiaire.nom)
   this.formBeneficiaires.controls.prenom.setValue(this.beneficiaire.prenom)
   this.formBeneficiaires.controls.telephone.setValue(this.beneficiaire.telephone)
   this.avatarUrl =  this.beneficiaire.avatar ? API_URL + '/'+this.beneficiaire.avatar : ''
   this.loader.active = false;
   this.toastr.success(res.message+' avec succes!', 'Edition Bénéficiaire');
 }},(error)=>{
   
       console.log('error getprofile',error);
       this.loader.active = false;
        this.toastr.error(error.error.message, 'Echec!');
  }); 
}

}



openModalTemplate(template: TemplateRef<any>): void{
  // this.openModal(template);
  this.modalRef = this.modalService.show(template);
}

setInfobeneficiaire(eventData: { data: any }){
  console.log('BulleTinAdhAdd', eventData.data);
  this.beneficiaire  =  eventData.data ;
  console.log('beneficiaire', eventData.data);
}

hideModalTemplate(eventData: { data: any }){
  console.log('modalMatricule', eventData.data);
  if ( eventData.data === 'hide-modal') {
    this.modalRef.hide();
  } /* else if ( eventData.data === 'hide-modalNumPolice') {
    this.modalRef.hide();
  } */
}

// prop methode #addMatricule
onloaderAdded(eventData: { active: boolean }){
  console.log('onloaderAdd', eventData.active);
  this.loader.active = eventData.active;
}

onDatepickerChange(e, typeDate) {

  const datePick = e ;
  if ( typeDate === 'dateNaiss') {
   this.dateNaissMatch =  FormatDate.getFormatDate('yy-mm-dd', datePick);
   console.log('this.dateNaissMatch', this.dateNaissMatch );
 } else if (typeDate === 'dateEtabli') {

   console.log('onDatepickerChange', e);
   // this.formAdherent.piece_ident.get('dateEtabli').patchValue(FormatDate.getFormatDate('yy-mm-dd', new Date()));
   this.dateEtabliMatch = FormatDate.getFormatDate('yy-mm-dd', datePick);
   console.log('this.dateEtabliMatch', this.dateEtabliMatch);
   this.formBeneficiaires.controls.dateNaissance.setValue(this.dateEtabliMatch)
 }


}
// prop methode #add url image
imageUrlAdd(eventData: { data: any }){
  console.log('BulleTinAdhAdd', eventData.data);
  this.avatarUrl = API_URL + '/' + eventData.data;
  console.log('this.avatarUrl', this.avatarUrl);
}
  ngOnInit(): void {
  
    this.formBeneficiaires = this.fb.group({
      // produitMutuelle: this.fb.array([], [Validators.required]),
     
       nom: ['', [Validators.required, Validators.minLength(2)]],
       prenom: ['', [Validators.required, Validators.minLength(2)]],
     sexe:['', [Validators.required, Validators.minLength(1)]],
       telephone: ['', [Validators.required, Validators.minLength(2),
        Validators.pattern(ValidationService.numberMobilDigit)]],
       /*  email:['',], */
       // dateNaissance: ['', [Validators.required]],
      // beneficiaires: this.buildChildrenInfos(this.bulletinAdh.beneficiaires),
      idBen: [this.idBeneficiaire, [Validators.required, Validators.minLength(2)]],
       idAdh: [this.idAdherent, [Validators.required]],
      

 });
    /* this.store.pipe(select(state => state.adherents.entities)).subscribe((adherents:any) => {
      
      // Faire quelque chose avec les données des adhérents mises à jour
      console.log('adherents.',adherents)
      if(adherents.status==200){
        this.adherents$ = adherents;
        console.log(' this.adherents$', this.adherents$);
      }
     
    }); */


   
     
   

/*    this.adherents$.subscribe(adherents => {
    // Faites quelque chose avec les données d'adhérents
    console.log('this.adherents$.subscribe',adherents); // Affiche les données d'adhérents mises à jour
  }); */
/*     loading$: Observable<boolean> = this.store.select(state => state.loading);
error$: Observable<string> = this.store.select(state => state.error); */
  }
  navigateToProfileAdh() {
    this.router.navigate([`adherent/profile/${this.idAdherent}`]);
   }
  
  onTab(number) {
    this.basicTab = false;
    this.accountTab = false;
    this.generalTab = false;

    if (number == '1') {
      this.basicTab = true;
    }
    else if (number == '2') {
      this.accountTab = true;
    }
    else if (number == '3') {
      this.generalTab = true;
    }
  }

}
