import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ActeService } from 'src/app/core/services/acte.service';
import { GroupeActeModel } from 'src/app/shared/models/groupeacte.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-acte', 
  templateUrl: './acte.component.html',
  styleUrls: ['./acte.component.scss']
})
export class ActeComponent implements OnInit {

  modalRef: BsModalRef;

    private groupeActes: GroupeActeModel[];
    public formgroupeActe ;
    private DefaultgroupeActe: GroupeActeModel[];
    loader = {active: false};
    searchValue: string;
    private q = '';
    listCategorie: GroupeActeModel[];
    idCategorie: string;

      // tslint:disable-next-line:no-shadowed-variable
      constructor(private modalService: BsModalService, private ActeService: ActeService, private fb: FormBuilder,
                  private toastr: ToastrService ) { }

/* 
  getGroupeActes() {
    this.ActeService. .getMedicament().subscribe((data: any) => {
        this.groupeActes = data.medicaments;

        console.log('this.medicaments=', this.medicaments);
        this.Defaultmedicaments = this.medicaments;
        console.log('this.Defaultmedicaments=', this.Defaultmedicaments);
    }); } */
    search() {
      //  console.log('rows', rows);
     // this.q = txt;
      console.log('q=', this.q);
      const columns =  this.DefaultgroupeActe[0] && Object.keys( this.DefaultgroupeActe[0]);
     // let testRows = [];

      // tslint:disable-next-line:max-line-length
      this.groupeActes = this.DefaultgroupeActe.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
       ));
      //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
     /*  console.log(' testRows',testRows); */
      console.log(' this.groupeActes=', this.groupeActes);
     // return testRows;
   }
    ajoutergroupeActe(): void {
         // Copy the form values over the Medicament object values
         const groupe = Object.assign({}, this.formgroupeActe.value);
         console.log('groupeActes ', groupe);
         this.loader.active = true;
         this.ActeService.addGroupeActe(groupe).subscribe((res: any) => {
             console.log('reponse', res);
             if (res.status === 200) {
              console.log('res.status === 200');
              this.loader.active = false;
              this.modalRef.hide();
              this.annulerFormData();
              this.showSuccess();
              this.listCategorie  = res.groupeacte;

              console.log('groupeActe=', this.listCategorie);
              this.DefaultgroupeActe= this.listCategorie;
              console.log('this.DefaultgroupeActe=', this.DefaultgroupeActe);
              }
         });

    }

    annulerFormData(): void {
      this.formgroupeActe.reset();
    }

  ngOnInit(): void {

    this.getCategorie();

    
    // form validateur
    this.formgroupeActe = this.fb.group({
      groupeActe: ['', [Validators.required, Validators.minLength(2)]],
      
    });
  }

  editionActe(item,template: TemplateRef<any>): void {
    console.log(item);
    this.idCategorie = item._id;
    this.formgroupeActe.get('groupeActe').setValue(item.groupeActe);
    this.modalRef = this.modalService.show(template);

  } 


  modifierCategorie(): void {
    // Copy the form values over the Medicament object values
    const modifcategorie = Object.assign({}, this.formgroupeActe.value);
    console.log('Categorie ', modifcategorie);
    this.loader.active = true;
    this.ActeService.editerCategorie(modifcategorie,this.idCategorie).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.listCategorie = [];
         this.listCategorie = res.groupeactes;
         this.loader.active = false;
         this.modalRef.hide();
         this.annulerFormData();
         this.showSuccess();
      }
  });
  
  }

  supprimCategorie(id): void {
    // Copy the form values over the Medicament object values
   
    console.log('id Catégories ', id);
    //this.loader.active = true;
  
    Swal.fire({
      title: 'Voulez-vous supprimer?',
      text: 'Vous ne pourrez plus récupérer ce fichier!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprime-le!',
      cancelButtonText: 'Non, gardez-le'
    }).then((result) => {
      if (result.value) {
  
   
        this.ActeService.deleteCategorie(id).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
           console.log('res.status === 200');
           this.listCategorie = res.GroupeActes;
           console.log('this.listCategorie',this.listCategorie);
           //this.loader.active = false;
           Swal.fire(
            'Supprimer!',
            'La catégorie a été supprimé.',
            'success'
          ) 
  
        }
    });
  
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  
  }

getCategorie(): void {


  this.ActeService.getCategorie().subscribe((data: any)=>{
    console.log('data', data);
    if(data.status===200){
      this.listCategorie = data.GroupeActes;

      console.log('this.listCategorie=', this.listCategorie);
      
    }
  }) 
   
}

openModal(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template);
}
/* hideModal(template: TemplateRef<any>) {
  this.modalRef.hide();
} */
showSuccess() {
  this.toastr.success('ajouter avec succes!',  'Ajout d\'une Catégorie');
}
}