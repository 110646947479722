import {ActionReducerMap} from '@ngrx/store';
import * as formAdherents from './adherents';
import * as formMedicaments from './medicaments';
import * as formVilles from './villes';
import * as formAdhesion from './adhesion';
import * as formCentre from './centre'

export interface State {

  adherents: formAdherents.AdherentState;
  adhesions: formAdhesion.AdhesionState;
  medicaments: formMedicaments.MedicamentState;
  villes: formVilles.VilleState;
  centres:formCentre.CentreState
}

export const reducers: ActionReducerMap<State> = {

    adherents: formAdherents.reducer,
    adhesions: formAdhesion.reducer,
    medicaments: formMedicaments.reducer,
    villes: formVilles.reducer,
    centres: formCentre.reducer
};

export const effects = [

    formAdherents.AdherentEffects,
    formMedicaments.MedicamentEffects,
    formVilles.VilleEffects,
    formAdhesion.AdhesionEffects,
    formCentre.CentreEffects

];
