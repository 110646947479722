import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdherentService } from 'src/app/core/services/adherent.service';

@Component({
  selector: 'app-modal-montant',
  templateUrl: './modal-montant.component.html',
  styleUrls: ['./modal-montant.component.scss']
})
export class ModalMontantComponent implements OnInit {

  @Input() idAdherent: string;
  @Output()loaderCreated = new EventEmitter<{ active: boolean }>();
  @Output()bulletinAdhesion = new EventEmitter<{data: any  }>();
  @Output()hideModalMatri = new EventEmitter<{data: string  }>();

 // @ViewChild('addMatricule') public modalTemplateRef: TemplateRef<any>;
  
  bookTitle: string;
  formMontant;
  submitted = false;
   bsModalRef: BsModalRef;
   
  constructor(private fb: FormBuilder, private adherent: AdherentService) { }

// ajouter Montant d'adhesion
  ajouterMontantAdh(){
    this.submitted = true;
    console.log('this.idAdherent', this.idAdherent);
    this.formMontant.controls.idAdh.setValue(this.idAdherent);
    if (this.formMontant) {
    this.loaderCreated.emit({ active: true });
    console.log(' this.idAdherent', this.idAdherent);


    const formMnt = Object.assign({}, this.formMontant.value);
    console.log('formMnt ', formMnt);
    this.adherent.modifierMontantAdhesion(formMnt).subscribe((res: any) => {
     console.log('reponse', res);
     if (res.status === 200) {
      const ba = res.Bulletinadhesion;
      this.bulletinAdhesion.emit({ data: ba });
      this.submitted = false;
      this.loaderCreated.emit({ active: false });
      this.hideModal('montant');
 }
}); 
    }

  }

  ngOnInit(): void {

     // form validateur
     this.formMontant = this.fb.group({
      montant: ['', [Validators.required, Validators.minLength(2)]],
       idAdh: ['', [Validators.required, Validators.minLength(2)]],
     /* specialite: ['', [Validators.required, Validators.minLength(2)]],
      telephone: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.pattern(ValidationService.emailRegex)]],
      genre: [''],
      website: [''],
      description: [''], */
    });
  }


  hideModal(template){
   // bsModalRef.hide();
    this.annulerFormData();
    if ( template === 'montant' ) {
      this.hideModalMatri.emit({data: 'hide-modal'});
    } 

  }

  annulerFormData(): void {
   
    this.formMontant.reset();
   
    //this.formMatricule.controls.idAdh.setValue('');
   
   
   
   }
}
