import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pop-up-comment-user',
  templateUrl: './pop-up-comment-user.component.html',
  styleUrls: ['./pop-up-comment-user.component.scss']
})
export class PopUpCommentUserComponent implements OnInit {
  @Input() commentaires: Array<any>;
  // @Output()loaderCreated = new EventEmitter<{ active: boolean }>();
  // @Output()bulletinAdhesion = new EventEmitter<{data: any  }>();
   @Output()hideModalComment = new EventEmitter<{data: string  }>();

  constructor() { }

  ngOnInit(): void {
    console.log('commentaires=', this.commentaires);
  }

  
  hideModal(template){
    // bsModalRef.hide();

     if ( template === 'num-police') {
       this.hideModalComment.emit({data: 'hide-modal'});
     }
 
   }

}
