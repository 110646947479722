
import { Action } from '@ngrx/store';
import { Villes } from './villes.models';

export enum Types {
ADD = '[Villes] Add: Start',
ADD_SUCCESS = '[Villes] Add: Success',
ADD_ERROR = '[Villes] Add: Error',
READ = '[Villes] Read: Start',
READ_SUCCESS = '[Villes] Read: Success',
READ_ERROR = '[Villes] Read: Error'

}

export class Add implements Action {

    readonly type = Types.ADD;
    constructor() {}

    }

export class AddSuccess implements Action {

    readonly type = Types.ADD_SUCCESS;
    constructor(public villes: Villes) {}
    }

export class AddError implements Action {
    readonly type = Types.ADD_ERROR;
    constructor(public error: string) {}

    }

export class Read implements Action {

readonly type = Types.READ;
constructor() {}

}

export class ReadSuccess implements Action {

readonly type = Types.READ_SUCCESS;
constructor(public villes: Villes) {}
}

export class ReadError implements Action {
readonly type = Types.READ_ERROR;
constructor(public error: string) {}

}

export type All = Add | AddSuccess | AddError | Read | ReadSuccess | ReadError;
