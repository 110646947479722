import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, } from '@angular/common/http';

import { Observable,of } from 'rxjs';
import { AdherentModel } from 'src/app/shared/models/adherent';

import * as fromAdhesions from '../../core/store/adhesion'
const API_URL = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class AdherentService {

 // Http Options
 httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

constructor(private httpClient: HttpClient) { }




  // Liste adherent
  getAdherent(): Observable<AdherentModel[]> {

    return this.httpClient.get<AdherentModel[]>(`${API_URL}/adherent/list`);
  }

  // Liste adherent
  getProfileAdherent(id): Observable<AdherentModel[]> {
  console.log('getProfileAdherent id',id);
  console.log('getProfileAdherent url',`${API_URL}/adherent/profile/${id}`);
  console.log('type of',typeof id);
    return this.httpClient.get<AdherentModel[]>(`${API_URL}/adherent/profile/${id}`);
  }

// pre enregistrer adherent
preEnrAdher(adherent: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.post<AdherentModel>(`${API_URL}/adherent/pre-enregistrer`, adherent, this.httpOptions
  );
}

//  enregistrer adherent
Enregistrer(adherent: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.post<AdherentModel>(`${API_URL}/adherent/enregistrer`, adherent, this.httpOptions
  );
}

modifierAdherent(id,adherent: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/modifier/${id}`, adherent, this.httpOptions
  );
}

// modifier matricule
modifierMatricule(matricule): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/editer/matricule`, matricule, this.httpOptions
  );
}



//  modifier NumeroPolice
modifierNumeroPolice(numPolice): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/editer/numeropolice`, numPolice, this.httpOptions
  );
}

// modifier montant d'adhesion
modifierMontantAdhesion(montant): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/editer/montant/adhesion`, montant, this.httpOptions
  );
}

// modifier image profile adherant
modifierImgProfilAdher(id, image): Observable<AdherentModel> {
  const data = new FormData();
  data.append('image-profile', image);
  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/editer/image/profile/${id}`, data);
}
//  enregistrer adherent
Adherer(adherent: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.post<AdherentModel>(`${API_URL}/adhesion/enregistrer`, adherent, this.httpOptions
  );
}

//  enregistrer adherent
addBeneficiaire(beneficiaire: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.post<AdherentModel>(`${API_URL}/beneficiaire/enregistrer`, beneficiaire, this.httpOptions
  );
}

/////// beneficiaire

//  modifier NumeroPolice Beneficiaire
modifierBenNumeroPolice(numPolice): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/beneficiaire/editer/numeropolice`, numPolice, this.httpOptions
  );
}
//  modifier matricule Beneficiaire
modifierBenMatricule(matricule): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/beneficiaire/editer/matricule`, matricule, this.httpOptions
  );
}

//  edition beneficiaire
editBeneficiaire(beneficiaire: AdherentModel): Observable<AdherentModel> {

  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/beneficiaire/editer`, beneficiaire, this.httpOptions
  );
}

// modifier image profile beneficiaire
modifierImgProfilBenef(id,idBen, image): Observable<AdherentModel> {
  console.log('id',id);
  console.log('idBen',idBen);
  const data = new FormData();
  data.append('image-profile', image);
  return this.httpClient.put<AdherentModel>(`${API_URL}/adherent/beneficiaire/editer/image/profile/${id}/${idBen}`, data);
}

//  supprimer benefi
deleteBeneficiaire(id,idBen,): Observable<AdherentModel> {

  return this.httpClient.get<AdherentModel>(`${API_URL}/adherent/beneficiaire/supprimer/${id}/${idBen}`);
}


/// STORE

getBulletinAdhesionStore(data:fromAdhesions.Adhesions):Observable<fromAdhesions.Adhesions>{
  console.log('getBulletinAdhesionStore',data);
  return of(data)
}

}
