import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-room-allotment',
  templateUrl: './room-allotment.component.html',
  styleUrls: ['./room-allotment.component.scss']
})
export class RoomAllotmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
