import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { LocalisationService } from 'src/app/core/services/localisation.service';
import { CentreSanteModel } from 'src/app/shared/models/centre_sante.model';
import { environment } from 'src/environments/environment';


// STORE 
import { Store } from '@ngrx/store';
import * as  fromRoot from '../../../../../core/store';
import * as fromCentres from '../../../../../core/store/centre'

const API_URL = environment.backend_url;
@Component({
  selector: 'app-all-centre-sante',
  templateUrl: './all-centre-sante.component.html',
  styleUrls: ['./all-centre-sante.component.scss']
})
export class AllCentreSanteComponent implements OnInit {

  modalRef: BsModalRef;
  private centreSantes: CentreSanteModel[];
  private DefaultcentreSantes: CentreSanteModel[];
  loader = {active: false};
  searchValue: string;
  private q = '';
  apiUrl = API_URL;
 // pagination
//  contentArray: string[] = new Array(50).fill('');
contentArray: string[] = new Array(10).fill('');
 returnedArray: string[];
 showBoundaryLinks: boolean = true;
 showDirectionLinks: boolean = true;

comments: Array<any> = [];
  constructor(private localSrv: LocalisationService, private router: Router, private store: Store, 
              private modalService: BsModalService) { }


  getCentres() {
    this.localSrv.getCentreSante().subscribe((data: any) => {


         if(data.status === 200){
          this.centreSantes = data.centreSantes;
          this.DefaultcentreSantes = this.centreSantes;
          console.log('this.centreSantes=', this.centreSantes);
          this.contentArray = new Array(this.centreSantes.length).fill('');
         }


    });
  }
  search() {
    //  console.log('rows', rows);
   // this.q = txt;
    console.log('q=', this.q);
    const columns =  this.DefaultcentreSantes[0] && Object.keys( this.DefaultcentreSantes[0]);

   // let testRows = [];

    // tslint:disable-next-line:max-line-length
    this.centreSantes = this.DefaultcentreSantes.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
     ));
    //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
   /*  console.log(' testRows',testRows); */
    console.log(' this.centreSantes=', this.centreSantes);
   // return testRows;
 }

 navigate(param) {
  this.router.navigate([`centre-sante/profile/${param}`]);
 }
 navigateToEdit(id,data){
  console.log('data centre',data)
  this.store.dispatch(new fromCentres.Read(data))
  this.router.navigate([`editer-centre-sante/${id}`])
 }

 linkEdition(param) {
  const centres = this.centreSantes.filter(item => item.slug === param);
  console.log(' centreSantes', centres);
  console.log(JSON.stringify(centres));
  // localStorage.setItem('adherent', JSON.stringify(adh));
  // this.router.navigate([`adherent/modifier/${param}`]);
 }
  ngOnInit(): void {
    this.getCentres();
    const centresInfos = this.store.select(store => console.log('store', store) );
    console.log(' centresInfos', centresInfos);
    this.contentArray = this.contentArray.map((v: string, i: number) => {
      return 'Line ' + (i + 1);
   });
    this.returnedArray = this.contentArray.slice(0, 5);
  }

 // pagination
 pageChanged(event: PageChangedEvent): void {
  console.log('event pagination', event);
  const startItem = (event.page - 1) * event.itemsPerPage;
  const endItem = event.page * event.itemsPerPage;
  this.returnedArray = this.contentArray.slice(startItem, endItem);
}

openModalComment(template: TemplateRef<any>,myComments){
  this.comments = myComments;
  this.modalRef = this.modalService.show(template);
}

hideModalTemplate(eventData: { data: any }){
  console.log('modal', eventData.data);
  if ( eventData.data === 'hide-modal') {
    this.modalRef.hide();
  }
}
openModalTemplate(template: TemplateRef<any>): void{
  // this.openModal(template);
  this.modalRef = this.modalService.show(template);
}

}
