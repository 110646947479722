import { Injectable } from '@angular/core';
import {Effect, Actions, ofType} from '@ngrx/effects';

import { Observable, of, zip  } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Centres } from './centres.models';
import { LocalisationService } from '../../services/localisation.service';


import * as fromActions from './centres.actions';
type Action = fromActions.All;

@Injectable()
export class CentreEffects {

 constructor( private actions$: Actions, private centreService: LocalisationService ) {}

 @Effect()
 add: Observable<Action> = this.actions$.pipe(
      ofType(fromActions.Types.ADD)
 );

/*  @Effect()
 read: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.Read>(fromActions.Types.READ),
    mergeMap(
        () => this.centreService.getCentreSante()
        .pipe(
            map((data: any) => new fromActions.ReadSuccess(data)),
            catchError(errror => of(new fromActions.ReadError(errror)))
        )
    )
 ); */

 @Effect()
 read: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.Read>(fromActions.Types.READ),
    mergeMap(action =>
        this.centreService.getInfoCentreStore(action.data).pipe(
        map((data: any) => new fromActions.ReadSuccess(data)),
        catchError(error => of(new fromActions.ReadError(error)))
      )
    )
  );
}
