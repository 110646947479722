import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LocalisationService } from 'src/app/core/services/localisation.service';
import { CommuneModel } from 'src/app/shared/models/commune.model';
import { VilleModel } from 'src/app/shared/models/ville.model';
import { Store, select } from '@ngrx/store';
import * as fromVilles from 'src/app/core/store/villes';
import * as fromRoot from 'src/app/core/store';
import { CentreSanteModel } from 'src/app/shared/models/centre_sante.model';

@Component({
  selector: 'app-edit-centre-sante',
  templateUrl: './edit-centre-sante.component.html',
  styleUrls: ['./edit-centre-sante.component.scss']
})
export class EditCentreSanteComponent implements OnInit,  OnChanges {

  public formCentreSante = null;
  @Input() listVilles: VilleModel[] = []; // Liste des villes
  @Input() listCommunes: CommuneModel[] = []; // Liste des communes
  villes$: Observable<fromVilles.Villes[]> ;
  selectedVille:any;
  selectedCommune:any;
  submitted = false;
  loader = {active: false};
  idCentre: string;

 @Input() centre: CentreSanteModel;
  constructor(private localSrv: LocalisationService, private fb: FormBuilder,
              private toastr: ToastrService, private store: Store<fromRoot.State>,
              private router: Router,private route: ActivatedRoute,) { }


  editerCentre(): void {

    this.submitted = true;
    console.log('this.formCentreSante.valid', this.formCentreSante.valid);
    if (this.formCentreSante.valid) {
      const centre = Object.assign({}, this.formCentreSante.value);

      console.log('centre ', centre);

      this.loader.active = true;
      this.localSrv.editerCentreSante(this.idCentre,centre).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
         console.log('res.status === 200');
         this.loader.active = false;
         this.submitted = false;

         this.formCentreSante.reset() ;
         this.showSuccess();
         this.router.navigate([`liste-centre-sante`]);

                     }
      },
       (error) => {
                 console.error('error edition CentreSante', error);
                 this.loader.active = false;
                 this.submitted = false;

            }

            ); 

          }// fin du if this.formCentreSante.valid

      }
  getVilles(): void {
        console.log('this.getVilles');
        this.localSrv.getVille().subscribe((data: any) => {
          if ( data.status === 200) {
            this.listVilles = data.villes;
            console.log('this.listVilles=', this.listVilles);

            if(this.centre){
              this.selectedVille =  this.listVilles.find(item=>this.centre?.ville ==item.nom)
              console.log(' this.selectedVille', this.selectedVille )
              this.formCentreSante.controls.idville.setValue(this.selectedVille._id)
               this.onVilleChange(this.selectedVille._id)
            }

           
          }

        });
    }
    getCentre(id):void {
      this.localSrv.getProfileCentreSante(id).subscribe((res: any) => {
        console.log('reponse', res);
        if (res.status === 200) {
      console.log('res.status === 200');
      this.centre = res.centreSante;
      console.log('this.centreSante', this.centre);
     
    this.formCentreSante.controls.nomCentre.setValue(this.centre?.nomCentre)
    this.formCentreSante.controls.nomResponsable.setValue(this.centre?.nomResponsable)
    this.formCentreSante.controls.prenomResponsable.setValue(this.centre?.prenomResponsable)
    this.formCentreSante.controls.fonction.setValue(this.centre?.fonction)
    this.formCentreSante.controls.etablissement.setValue(this.centre?.etablissement)
    this.formCentreSante.controls.etablissement.setValue(this.centre.hasOwnProperty('etablissement') ?
    this.centre?.etablissement: '')
    this.selectedVille =  this.listVilles.find(item=>this.centre?.ville ==item.nom)
    console.log(' this.selectedVille', this.selectedVille )
    this.formCentreSante.controls.idville.setValue(this.selectedVille._id)
     this.onVilleChange(this.selectedVille._id)

  //   this.selectedVille =  this.listVilles.find(item=>this.centre?.ville ==item.nom)
  //  console.log(' this.selectedVille', this.selectedVille )
  //   this.onVilleChange(this.selectedVille._id)
  
     /*  this.idAdherModal = this.adherent._id;
      this.adhesion = res.profileAdherent.bulletin_adhesion;
      console.log(' this.adhesion',  this.adhesion);
      this.montantAdh = this.adhesion.montantAdhesion;
      console.log(' this.adhesion.adherent.avatar', this.adhesion.adherent.avatar);
      this.avatarUrl = this.adhesion.adherent.avatar ?  API_URL +'/'+ this.adhesion.adherent.avatar : this.avatarUrl ;
      console.log(' this.avatarUrl',  this.avatarUrl); */
     }
});
    }
    navigateTo() {
      this.router.navigate([`liste-centre-sante`]);
     }
  ngOnInit(): void {
    console.log('edition centre de santer');
      // identifiant du beneficiaire

    this.formCentreSante = this.fb.group({
      nomCentre: ['', [Validators.required, Validators.minLength(2)]],
      nomResponsable: ['', [Validators.required, Validators.minLength(2)]],
      prenomResponsable: ['', [Validators.required, Validators.minLength(2)]],
      fonction: ['', [Validators.required, Validators.minLength(2)]],
      idville: ['', [Validators.required, Validators.minLength(2)]],
      commune: ['', [Validators.required, Validators.minLength(2)]],
      etablissement:['', [Validators.required]],
      description:[''],
      // telephone: ['', [Validators.required, Validators.minLength(2),
      //   Validators.pattern(ValidationService.numberMobilDigit)]],
      // email: ['' , [Validators.pattern(ValidationService.emailRegex)] ],
      situationGeo: [''/* , [Validators.required, Validators.minLength(2)] */],

  });

    this.getVilles();
   // this.villes$ =   this.store.pipe(select(fromVilles.));
   //  console.log('villes$', this.villes$);
   // this.store.dispatch(new fromVilles.Read());

   this.idCentre = this.route.snapshot.params.id;
      console.log('this.idCentre',this.idCentre)
      if (this.idCentre) {
       

        this.store.pipe(select(state =>   state.centres.entities )).subscribe((centre:any) => {
      
          // Faire quelque chose avec les données des adhérents mises à jour
          console.log('centre=',centre)
          this.centre = centre;
           if(!centre){
               this.getCentre(this.idCentre)
           }else{
            //this.centre= centre.find(item=>item._id==  this.idCentre)
            console.log('this.centre',this.centre )
            this.formCentreSante.controls.nomCentre.setValue(centre?.nomCentre)
          this.formCentreSante.controls.nomResponsable.setValue(centre?.nomResponsable)
          this.formCentreSante.controls.prenomResponsable.setValue(centre?.prenomResponsable)
          this.formCentreSante.controls.fonction.setValue(centre?.fonction)
          this.formCentreSante.controls.etablissement.setValue(centre.hasOwnProperty('etablissement') ?
            centre?.etablissement: '')
            //this.selectedCommune = this.listCommunes.find(item=>centre?.commune ==item.nom)
            //console.log(' this.selectedCommune', this.selectedCommune )
           }
          
        });;
       
          // const agent  = localStorage.getItem('user_mugasci');
          // this.infoUser = JSON.parse(agent);
         //  console.log('infoUser', this.infoUser);
           //this.idAgent = this.getuserAgent(this.infoUser._id);
          // this.getProfile(this.idAdherent);
         //  this.getProduit();

         } else {

          this.router.navigate(['/adherents/liste-adherent']);

         }
     
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    console.log('changes.listVilles=',changes.listVilles);
    console.log('changes.centre=',changes.centre);
    if (changes.listVilles) {
      const nouvelleValeur = changes.listVilles.currentValue;
      const ancienneValeur = changes.listVilles.previousValue;

      // Traitez les changements de maPropriete ici
      console.log('Ma propriété a été modifiée. Nouvelle valeur :', nouvelleValeur);
      console.log('Ancienne valeur :', ancienneValeur);
      this.selectedVille =  nouvelleValeur.find(item=>this.centre?.ville ==item.nom)
            console.log(' this.selectedVille', this.selectedVille )
            this.onVilleChange(this.selectedVille._id)
    }
  }

  onVilleChange(villeId: string) {
    console.log('villeId:=', villeId);
    // Effectuer une requête HTTP pour récupérer la liste des communes selon l'ID de la ville sélectionnée
    this.localSrv.getCommuneByVille(villeId).subscribe((data: any) => {
      if ( data.status === 200) {
        this.listCommunes = data.communes;
        console.log('this.listCommunes=', this.listCommunes);
        this.selectedCommune = this.listCommunes.find(item=>this.centre?.commune ==item.nom)
        console.log(' this.selectedCommune', this.selectedCommune )
        this.formCentreSante.controls.commune.setValue(this.selectedCommune?.nom)
      }
    });
  }

  showSuccess() {
    this.toastr.success('Editer  avec succes.', 'Edition d\'un centre!');
  }


}
