import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { FormatDate } from 'src/app/core/patterns/FormatDate';
import { Helpers } from 'src/app/core/patterns/helpers';
import { StatistiqueService } from 'src/app/core/services/statistique.service';

@Component({
  selector: 'app-form-filtre-date-item',
  templateUrl: './form-filtre-date-item.component.html',
  styleUrls: ['./form-filtre-date-item.component.scss']
})
export class FormFiltreDateItemComponent implements OnInit {
  @Output()dateFilter= new EventEmitter<{data: object }>();
  @Output()dateItemFilter= new EventEmitter<{data: object }>();
  formFilterDateTab: boolean = true;
  formFilterDateItemTab: boolean;

  // statistique
  @Input () totalAdherentStat: string = '';
  @Input () moisAdhesionStat: string  = '';
  @Input () anneAdhesionStat: string  = '';


  FriendsTab: boolean;
  selectedCar: number;
  monthselected?: any = null;
  yearselected?: any = null;

  listeMois = FormatDate.ListMonths();
  listeAnnee = FormatDate.ListYears();
    cars = [
        { id: 1, name: 'Volvo' },
        { id: 2, name: 'Saab' },
        { id: 3, name: 'Opel' },
        { id: 4, name: 'Audi' },
    ];
  selectedOption: any;
  previewOption: any;
  constructor(private statService: StatistiqueService, private router: Router, private store: Store,
              private fb: FormBuilder) { }
  onSelect(event: TypeaheadMatch,item): void {
    if( item === 'mois') {
     // this.monthselected = Helpers.checkArray(this.listeMois, this.monthselected) ? event.item.date : null;
     this.monthselected =  event.item.title;
     console.log('this.monthselected', this.monthselected);
    } else if( item === 'annee') {
     // this.yearselected = Helpers.checkArray(this.listeAnnee, this.yearselected) ? event.item.date : null;
     this.yearselected =  event.item ;
     console.log('this.yearselected', this.yearselected);
    }
    this.selectedOption = event.item;
  }

  onPreview(event: TypeaheadMatch): void {
    if (event) {
      this.previewOption = event.item;
      console.log('this.previewOption',this.previewOption);
    } else {
      this.previewOption = null;
      console.log('this.previewOption',this.previewOption);
    }
  }
  onTab(number) {
    this.formFilterDateTab = false;
    this.formFilterDateItemTab = false;
    this.FriendsTab = false;

    if (number == '1') {
      this.formFilterDateTab = true;
    }
    else if (number == '2') {
      this.formFilterDateItemTab = true;
    }
    else if (number == '3') {
      this.FriendsTab = true;
    }
  }
  //
  Rechercher(item){
    if(item == 'date'){
         this.dateFilter.emit({
          data: { mois:FormatDate.getMonthNumber(this.monthselected) , annee: this.yearselected}
         })
    } else if(item == 'items'){
      this.dateItemFilter.emit({
        data: { mois: this.monthselected, annee: this.yearselected}
       })
    }

  }
  ngOnInit(): void {
  }

}
