import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProduitService } from 'src/app/core/services/produit.service';
import { GroupeProduitModel } from 'src/app/shared/models/groupeproduit';
import { ProduitModel } from 'src/app/shared/models/produit';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-produit',
  templateUrl: './all-produit.component.html',
  styleUrls: ['./all-produit.component.scss']
})
export class AllProduitsComponent implements OnInit {

  private produits: ProduitModel[];
  private groupeProduit: GroupeProduitModel[];
  private Defaultproduits: ProduitModel[];

  modalRef: BsModalRef;

  public formProduit ;
  loader = {active: false};
  searchValue: string;
  private q = '';
  idProduit: any;
  groupeId: any;


  constructor(private produitService: ProduitService, private fb: FormBuilder,
              private toastr: ToastrService, private modalService: BsModalService) { }


  getProduits() {
    this.produitService.getProduit().subscribe((data: any) => {
      this.produits = data.produits;
      this.Defaultproduits  = this.produits;
      console.log('this.produits=', this.produits);
    });
  }
   
getgroupeProduit() {
  this.produitService.getGroupeProduit().subscribe((data: any) => {
      this.groupeProduit = data.GroupeProduits;

      console.log('this.groupeProduit=', this.groupeProduit);
     
  });

} 
  search() {
    //  console.log('rows', rows);
   // this.q = txt;
    console.log('q=', this.q);
    const columns =  this.Defaultproduits[0] && Object.keys( this.Defaultproduits[0]);

   // let testRows = [];

    // tslint:disable-next-line:max-line-length
    this.produits = this.Defaultproduits.filter((row) => columns.some((column) => row[column].toString().toLowerCase().indexOf( this.q ) > -1
     ));
    //  let testRows= rows.filter((row)=>row.nom.toLowerCase().indexOf(q) > -1 );
   /*  console.log(' testRows',testRows); */
    console.log(' this.produits=', this.produits);
   // return testRows;
 }

 ajouterProduit(){
   // Copy the form values over the patient object values
   const produit = Object.assign({}, this.formProduit.value);
   console.log('produit ', produit);
   this.loader.active = true;
   this.produitService.addProduit(produit).subscribe((res: any) => {
       console.log('reponse', res);
       if (res.status === 200) {
        console.log('res.status === 200');
        this.loader.active = false;
        this.produits = res.produits;
        this.modalRef.hide();
        this.annulerFormData();
        this.showSuccess();

                    }
   });
 }

 // edition 
 editionProduit(item,template: TemplateRef<any>): void {
    console.log('editionProduit item',item);
    this.idProduit = item._id;
    this.formProduit.get('groupe').setValue(item.groupe);
    this.groupeId = this.formProduit.get('groupe').value;
    console.log('this.groupeId',this.groupeId );
    this.formProduit.get('nom').setValue(item.nom);
    this.formProduit.get('montant').setValue(item.montant);
    // tslint:disable-next-line:no-unused-expression
    item.taux ?  this.formProduit.get('taux').setValue(item.taux) : '';
    this.formProduit.get('description').setValue(item.description);
  
    this.modalRef = this.modalService.show(template);
  

 }


 modifierProduit(): void {
  // Copy the form values over the Medicament object values
  const modifproduit = Object.assign({}, this.formProduit.value);
  console.log('Actes ', modifproduit);
  this.loader.active = true;
  this.produitService.editerProduit(modifproduit, this.idProduit).subscribe((res: any) => {
      console.log('reponse', res);
      if (res.status === 200) {
       console.log('res.status === 200');
       this.produits = res.actes;
       this.loader.active = false;
       this.modalRef.hide();
       this.annulerFormData();
       this.showSuccess();
    }
});

}

 // supprimer
 supprimerProduit(id): void {
    // Copy the form values over the Medicament object values
 
    console.log('id Produit ', id);
    // this.loader.active = true;
  
    Swal.fire({
      title: 'Voulez-vous supprimer?',
      text: 'Vous ne pourrez plus récupérer ce fichier!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprime-le!',
      cancelButtonText: 'Non, gardez-le'
    }).then((result) => {
      if (result.value) {
  
   
        this.produitService.deleteProduit(id).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
           console.log('res.status === 200');
           this.produits = res.produits;
          // console.log('this.groupeProduit',this.groupeProduit);
           // this.loader.active = false;
           Swal.fire(
            'Supprimer!',
            'La catégorie a été supprimé.',
            'success'
          ) 
  
        }
    });
  
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
 }

  ngOnInit(): void {
    this.formProduit = this.fb.group({
      groupe: ['', [Validators.required, Validators.minLength(2)]],
      nom: ['', [Validators.required, Validators.minLength(2)]],
      montant: ['', [Validators.required, Validators.minLength(2)]],
      description: [''],
      taux:[''],
  });
    this.getProduits();
    this.getgroupeProduit();
  }
  annulerFormData(): void {
    this.formProduit.reset();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    }
  showSuccess() {
    this.toastr.success('Ajout d\'un produit. Voir la liste des produits', 'Ajouter avec succes!');
  }


}
