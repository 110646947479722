import { viewClassName } from '@angular/compiler';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

import { fil } from 'date-fns/locale';
import { AdherentService } from 'src/app/core/services/adherent.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, AfterViewInit {
  @ViewChild('fileupload') fileupload: ElementRef<HTMLInputElement>;
  @ViewChild('imgprevious') imgprevious: ElementRef<HTMLInputElement>;
  @ViewChild('filename') filename: ElementRef<HTMLInputElement>;

  @Input() idAdherent: string;
  @Input() idBeneficiaire: string;
  @Output()loaderCreated = new EventEmitter<{ active: boolean }>();
  @Output()hideModalUploadFile = new EventEmitter<{data: string  }>();
  @Output()imageUrl = new EventEmitter<{data: string  }>();
  @Output()beneficiaire = new EventEmitter<{data: any  }>();

 // wrapperElement = (this.el.nativeElement as HTMLElement);
 addActive=false;
 msgErrorImage='';
 errorHidden = true;
  sizeImg = 2 * 1024 * 1024;
  fileUploded = null;
 constructor(private adherServ: AdherentService, private renderer: Renderer2, private el: ElementRef) { }

  defaultBtnActive() {
    console.log('fileupload', this.fileupload);
    this.fileupload.nativeElement.click();
  }

  changeInputFile(e) {
     console.log('fileupload value', this.fileupload.nativeElement.value);
     if(this.fileupload.nativeElement.value === ''){
          this.fileUploded = null;
          return this.imgprevious.nativeElement.src = ' ';
     }
     console.log('image', this.fileupload.nativeElement.files );
     this.fileUploded = this.fileupload.nativeElement.files[0];

    
     if (this.fileUploded && this.fileUploded.size < this.sizeImg) {
      this.errorHidden = true;
      this.msgErrorImage = '';

      if (this.fileUploded.type === 'image/jpeg' || this.fileUploded.type === 'image/png') {
        this.errorHidden = true;
        this.msgErrorImage = '';
        const url = URL.createObjectURL(this.fileUploded);
        this.imgprevious.nativeElement.src = url;
        this.filename.nativeElement.textContent = this.fileUploded.name;

        this.addActive = true;
      } else {
           this.errorHidden = false;
           this.msgErrorImage = 'Invalide format image. Telecharger une image de type *.jpeg ou *.png ';
      }



     } else{
      this.errorHidden = false;
      this.msgErrorImage = 'Taille de l\' image trog grande telecharger. Utiliser des images inferieur 2Mo ';
     }

  }
  uploadImage(){
    console.log('this.fileUploded', this.fileUploded);
    if (this.fileUploded) {
      this.loaderCreated.emit({ active: true });
      this.adherServ.modifierImgProfilAdher(this.idAdherent, this.fileUploded).subscribe(( res: any ) => {
        console.log('res uploadImage', res);
        if (res.status===200){
              const urlImg = res.imageUrl;
              this.imageUrl.emit({ data: urlImg });
              this.loaderCreated.emit({ active: false });
              this.hideModal('image-profile');
        }
            })


        }


  }

  uploadImageBenf(){
    console.log('this.fileUploded', this.fileUploded);
    console.log('this.idBeneficiaire', this.idBeneficiaire);
    if (this.fileUploded) {
      this.loaderCreated.emit({ active: true });
      this.adherServ.modifierImgProfilBenef(this.idAdherent, this.idBeneficiaire, this.fileUploded).subscribe(( res: any ) => {
        console.log('res uploadImage', res);
        if (res.status===200){
              const urlImg = res.imageUrl;
              this.imageUrl.emit({ data: urlImg });
              this.loaderCreated.emit({ active: false });
              this.hideModal('image-profile');
        }
            },(error)=>{
              console.log('upload image error', error);
              this.loaderCreated.emit({ active: false });
            })


        }
  }
  closeImgPrev() {
    this.imgprevious.nativeElement.src = ' ';
    this.addActive = false;
    this.fileUploded = null;
   }
   
  hideModal(template){
    // bsModalRef.hide();
    // this.annulerFormData();
     if ( template === 'image-profile') {
       this.hideModalUploadFile.emit({data: 'hide-modal'});
     }
 
   }

  ngOnInit(): void {

     console.log('idBeneficiaire',this.idBeneficiaire)
     console.log('beneficiaire',this.beneficiaire)
  }
  ngAfterViewInit() {
    
  }
}


