import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './pages/signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailComponent } from './email/email.component';
import { ChatComponent } from './chat/chat.component';
import { ChartsComponent } from './charts/charts.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { ContactsComponent } from './contacts/contacts.component';
import { BlogComponent } from './blog/blog.component';
import { SocialComponent } from './social/social.component';
import { SettingsComponent } from './settings/settings.component';
import { ComposeEmailComponent } from './email/compose-email/compose-email.component';

import { ComposeEmailDetailsComponent } from './email/compose-email-details/compose-email-details.component';
import { InvoicesComponent } from './payments/invoices/invoices.component';
import { InvoiceDetailComponent } from './payments/invoices/invoice-detail/invoice-detail.component';
import { PaymentsComponent } from './payments/payments.component';
import { AddpaymentsComponent } from './payments/addpayments/addpayments.component';
import { DepartmentComponent } from './department/department.component';
import { OurCentersComponent } from './our-centers/our-centers.component';
import { OurStaffComponent } from './our-staff/our-staff.component';
import { AllotedRoomsComponent } from './room-allotment/alloted-rooms/alloted-rooms.component';
import { AddAllotmentComponent } from './room-allotment/add-allotment/add-allotment.component';
import { RoomAllotmentComponent } from './room-allotment/room-allotment.component';
import { PatientsComponent } from './patients/patients.component';
import { AllPatientsComponent } from './patients/all-patients/all-patients.component';
import { AddPatientComponent } from './patients/add-patient/add-patient.component';
import { AddAdherentComponent } from './pages/adherent/add-adherent/add-adherent.component';
import { AllAdherentComponent } from './pages/adherent/all-adherent/all-adherent.component';
import { AdherentProfileComponent } from './pages/adherent/adherent-profile/adherent-profile.component';
import { PatientProfileComponent } from './patients/patient-profile/patient-profile.component';
import { PatientInvoicesComponent } from './patients/patient-invoices/patient-invoices.component';
import { AppointmentComponent } from './appointment/appointment.component';
// import { DoctorsComposnent } from './doctors/doctors.component';
import { AllDoctorsComponent } from './doctors/all-doctors/all-doctors.component';
import { AddDoctorsComponent } from './doctors/add-doctors/add-doctors.component';
import { DoctorsProfileComponent } from './doctors/doctors-profile/doctors-profile.component';
import { DoctorsScheduleComponent } from './doctors/doctors-schedule/doctors-schedule.component';
import { EventsComponent } from './doctors/doctors-schedule/events/events.component';
import { CovidComponent } from './dashboard/covid/covid.component';
import { AddUsersComponent } from './pages/utilisateur/add-users/add-users.component';
import { AllUsersComponent } from './pages/utilisateur/all-users/all-users.component';
import { AuthGuard } from 'src/app/shared/auth/guard';
import { ConnexionComponent } from './pages/connexion/connexion.component';
import { AddProduitComponent } from './pages/produit/add-produit/add-produit.component';
import { AllProduitsComponent } from './pages/produit/all-produit/all-produit.component';
import { AllMedicamentsComponent } from './medicament/all-medicaments/all-medicaments.component';
import { AllActeComponent } from './acte/all-acte/all-acte.component';
import { ActeComponent } from './acte/acte.component';
import { EditFeuilleSoinComponent } from './feuille-soin/edit-feuille-soin/edit-feuille-soin.component';
import { AddFeuilleSoinComponent } from './feuille-soin/add-feuille-soin/add-feuille-soin.component';
import { AddOrdannanceComponent } from './feuille-soin/add-ordannance/add-ordannance.component';
import { AllAffectionComponent } from './affection/all-affection/all-affection.component';
import { ConsultOrdonnanceComponent } from './pharmacie/consult-ordonnance/consult-ordonnance.component';
import { DetailsOrdonnanceComponent } from './pharmacie/details-ordonnance/details-ordonnance.component';
import { ProduitComponent } from './pages/produit/produit.component';
import { AddCentreSanteComponent } from './pages/centre-sante/add-centre-sante/add-centre-sante.component';
import { AllCentreSanteComponent } from './pages/centre-sante/all-centre-sante/all-centre-sante.component';
import { CentreSanteProfileComponent } from './pages/centre-sante/centre-sante-profile/centre-sante-profile.component';
import { BeneficiaireProfileComponent } from './pages/adherent/beneficiaire-profile/beneficiaire-profile.component';
import { EditAdherentComponent } from './pages/adherent/edit-adherent/edit-adherent.component';
import { EditCentreSanteComponent } from './pages/centre-sante/edit-centre-sante/edit-centre-sante.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { title: 'MUGASCI | Dashboard',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },

  {
    path: 'app-inbox',
    component: EmailComponent,
    data: { title: 'MUGASCI | Inbox',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'app-chat',
    component: ChatComponent,
    data: { title: 'MUGASCI | Chat',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'chartelement',
    component: ChartsComponent,
    data: { title: 'MUGASCI | Chart-element',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },

  {
    path: 'todolist',
    component: TodoListComponent,
    data: { title: 'MUGASCI | TodoList',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'filemanager',
    component: FilemanagerComponent,
    data: { title: 'MUGASCI | Filemanager',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: { title: 'MUGASCI | Contacts',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'blog',
    component: BlogComponent,
    data: { title: 'MUGASCI | Blog',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'social',
    component: SocialComponent,
    data: { title: 'MUGASCI | Social',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'MUGASCI | Settings',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'composeemail',
    component: ComposeEmailComponent,
    data: { title: 'MUGASCI | ComposeEmail',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'composeemail/composeemail-details',
    component: ComposeEmailDetailsComponent,
    data: { title: 'MUGASCI | ComposeEmailDetails',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },

  {
    path: 'doctorschedule/events',
    component: EventsComponent,
    data: { title: 'MUGASCI | Events',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },

  {
    path: 'payments',
    component: PaymentsComponent,
    data: { title: 'MUGASCI | Payments',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'payments/addpayment',
    component: AddpaymentsComponent,
    data: { title: 'MUGASCI | AddPayments',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'payments/invoices',
    component: InvoicesComponent,
    data: { title: 'MUGASCI | Invoices',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'payments/invoices/invoice-detail',
    component: InvoiceDetailComponent,
    data: { title: 'MUGASCI | InvoiceDetail',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'pages/signup',
    component: SignupComponent,
    data: { title: 'MUGASCI | Signup',
    role: ['SUPER_ADMIN'], }
  },
  {
    path: 'connexion',
    component: ConnexionComponent,
    data: { title: 'MUGASCI | connexion',
    role: ['USER_AGENT', 'ADMIN_AGENT', 'SUPER_ADMIN'], }
  },
  {
    path: 'departments',
    component: DepartmentComponent,
    data: { title: 'MUGASCI | Departments',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'our-centers',
    component: OurCentersComponent,
    data: { title: 'MUGASCI | OurCenters',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'our-staff',
    component: OurStaffComponent,
    data: { title: 'MUGASCI | OurStaff',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'room-allotment',
    component: RoomAllotmentComponent,
    data: { title: 'MUGASCI | RoomAllotment',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'room-allotment/alloted-rooms',
    component: AllotedRoomsComponent,
    data: { title: 'MUGASCI | AllotedRooms',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'room-allotment/add-alloted-rooms',
    component: AddAllotmentComponent,
    data: { title: 'MUGASCI | Add-Allotment-Rooms',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'patients',
    component: PatientsComponent,
    data: { title: 'MUGASCI | Patients',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/all-patients',
    component: AllPatientsComponent,
    data: { title: 'MUGASCI | AllPatients',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/add-patient',
    component: AddPatientComponent,
    data: { title: 'MUGASCI | AddPatient',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/patient-profile',
    component: PatientProfileComponent,
    data: { title: 'MUGASCI | PatientProfile',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/patient-invoices',
    component: PatientInvoicesComponent,
    data: { title: 'MUGASCI | PatientInvoices',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  // produits
  {
    path: 'liste-groupe-produits',
    component: ProduitComponent,
    data: { title: 'MUGASCI | AllGroupeProduits',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },

  {
    path: 'produits/all-produits',
    component: AllProduitsComponent,
    data: { title: 'MUGASCI | AllProduits',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'produits/add-produit',
    component: AddProduitComponent,
    data: { title: 'MUGASCI | AddProduit',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'adherents/ajouter-adherent',
    component: AddAdherentComponent,
    data: { title: 'MUGASCI | AjouterAdherent',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'adherent/:id',
    component: EditAdherentComponent,
    data: { title: 'MUGASCI | ModifierAdherent',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'adherents/liste-adherent',
    component: AllAdherentComponent,
    data: { title: 'MUGASCI | ListeAdherent',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  /* ne pas toucher */
 /*  {
    path: 'adherent/editer-adherent/:id',
    component: AddAdherentComponent,
    data: { title: 'MUGASCI | EditAdherent' },
    canActivate: [AuthGuard]
  }, */
  /* ne pas toucher */
  {
    path: 'adherent/profile/:id',
    component: AdherentProfileComponent,
    data: { title: 'MUGASCI | ProfileAdherent',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'adherent/beneficiaire/profile/:id/:id_ben',
    component: BeneficiaireProfileComponent,
    data: { title: 'MUGASCI | ProfileBeneficiaire',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'appointments',
    component: AppointmentComponent,
    data: { title: 'MUGASCI | AllDoctors',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  /* {
    path: 'doctors',
    component: DoctorsComponent,
    data: { title: 'MUGASCI | Doctors' }
  }, */
  {
    path: 'all-doctors',
    component: AllDoctorsComponent,
    data: { title: 'MUGASCI | AllDoctors',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'add-doctors',
    component: AddDoctorsComponent,
    data: { title: 'MUGASCI | AddDoctors',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'doctors-profile',
    component: DoctorsProfileComponent,
    data: { title: 'MUGASCI | DoctorsProfile',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'doctors-schedule',
    component: DoctorsScheduleComponent,
    data: { title: 'MUGASCI | DoctorsSchedule',
    role: ['SUPER_ADMIN'], },
    canActivate: [AuthGuard]
  },
  {
    path: 'covid-19',
    component: CovidComponent,
    data: { title: 'MUGASCI | Covid-19',
    role: ['SUPER_ADMIN'],
  },
    canActivate: [AuthGuard],

  },
 // CENTRE SANTE
 {
  path: 'ajouter-centre-sante',
  component: AddCentreSanteComponent,
  data: { title: 'MUGASCI | AJOUT CENTRE',
  role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'],
},
  canActivate: [AuthGuard],

},
{
  path: 'editer-centre-sante/:id',
  component: EditCentreSanteComponent,
  data: { title: 'MUGASCI | AJOUT CENTRE',
  role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'],
},
  canActivate: [AuthGuard],

},
{
  path: 'liste-centre-sante',
  component: AllCentreSanteComponent,
  data: { title: 'MUGASCI | LISTE CENTRE',
  role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'],
},
  canActivate: [AuthGuard],

},
{
  path: 'centre-sante/profile/:id',
  component: CentreSanteProfileComponent,
  data: { title: 'MUGASCI | Profile Centre Sante',
  role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'], },
  canActivate: [AuthGuard]
},

  // MEDECINE
   {
    path: 'liste-medicaments',
    component: AllMedicamentsComponent,
    data: { title: 'MUGASCI | LISTEMEDOC',
    role: ['USER_CENTRE', 'ADMIN_CENTRE'],
   },
    canActivate: [AuthGuard],

  },
  {
    path: 'liste-actes',
    component: AllActeComponent,
    data: { title: 'MUGASCI | LISTE GROUPE ACTE',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'],
   },
    canActivate: [AuthGuard]
  },

  {
    path: 'liste-groupe-actes',
    component: ActeComponent,
    data: { title: 'MUGASCI | LISTE ACTE',
    role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'],
  },
    canActivate: [AuthGuard]
  },
 
  {
    path: 'edition-feuille-soin',
    component: EditFeuilleSoinComponent,
    data: { title: 'MUGASCI | EDITION FEUILLE SOIN',
    role: ['USER_CENTRE', 'ADMIN_CENTRE'],
  },
    canActivate: [AuthGuard]
  },
  {
    path: 'ajouter-feuille-soin',
    component: AddFeuilleSoinComponent,
    data: { title: 'MUGASCI | AJOUTER FEUILLE SOIN',
    role: ['USER_CENTRE', 'ADMIN_CENTRE'],
  },
    canActivate: [AuthGuard]
  },
  {
    path: 'bulettin-soin/ajouter-ordonnance',
    component: AddOrdannanceComponent,
    data: { title: 'MUGASCI | AJOUTER ORDONNANCE',
    role: ['USER_CENTRE', 'ADMIN_CENTRE'],
  },
    canActivate: [AuthGuard]
  },

   // PHARMACIE
    {
    path: 'pharmacie/liste-ordonnance',
    component: ConsultOrdonnanceComponent,
    data: { title: 'RECHERCHE BULLETIN SOIN',
    role: ['USER_PHARMA', 'ADMIN_PHARMA'],
  },
    canActivate: [AuthGuard]
  },
  {
    path: 'pharmacie/details-ordonnance',
    component: DetailsOrdonnanceComponent,
    data: { title: 'DETAILS ORDONNANCE',
    role: ['USER_PHARMA', 'ADMIN_PHARMA'],
  },
    canActivate: [AuthGuard]
  },

  // ADMIN
  {
    path: 'ajouter-utilisateur',
    component: AddUsersComponent,
    data: { title: 'AJOUTER UTILISATEUR',
            role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'],
          },
    canActivate: [AuthGuard]
  },
  {
    path: 'liste-utilisateur',
    component: AllUsersComponent,
    data: { title: '',
            role: ['USER_AGENT', 'ADMIN_AGENT','SUPER_ADMIN'],
       },
    canActivate: [AuthGuard]
  },
  {
    path: 'liste-affections',
    component: AllAffectionComponent,
    data: { title: 'MUGASCI | LISTE AFFECTION' },
    canActivate: [AuthGuard]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {
  static components = [
    SignupComponent,
    DashboardComponent

  ];

}
