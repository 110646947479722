import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BulletinSoinService } from 'src/app/core/services/bulletin-soin.service';
import { MedicamentModel } from 'src/app/shared/models/medicaments.model';

@Component({
  selector: 'app-add-ordannance',
  templateUrl: './add-ordannance.component.html',
  styleUrls: ['./add-ordannance.component.scss']
})
export class AddOrdannanceComponent implements OnInit {

  FeedTab: boolean ;
  ActivityTab: boolean;
  Prescriptions: boolean= true;
  BulletinDeSoin: any;
  inputPlaceholderSearch = 'rechercher un medicament';
  FormMedicament = null;
  FormOrdonnance = null;
  FormPrescription = null;
  listMedicament: MedicamentModel[] = [];
  itemRow = null;
  titreCardTxt = 'liste médicament';
  submitted = false;
  cardloader = {active: false};
  BSordonances = [];
  ordonanceMedicale: any = null;
  activeBtnAddOrd = true;
  loader = {active: false};
  constructor(private bsService: BulletinSoinService, private fb: FormBuilder,
              private router: Router, private toastr: ToastrService, ) { }

addPrescript(){
  if (this.ordonanceMedicale.valid) {
    this.loader.active = true;
    const ordonnance = Object.assign({}, this.ordonanceMedicale.value);
    console.log('ordonnance ', ordonnance);
    ordonnance.idbs = this.BulletinDeSoin._id;
    console.log('ordonnance ', ordonnance);
    this.bsService.modifierOrdonnance(ordonnance).subscribe((res: any) => {
      console.log('reponse', res);
      if (res.status === 200) {
        this.showSuccess();
        this.annulerFormData();
        this.listMedicament = [];
        this.submitted = false;
        this.loader.active = false;
        this.activeBtnAddOrd = false;

      }
  }) 
  }
}
addMedoc(item, index){
  console.log('item=', item);
  console.log('index', index);
  console.log('itemRow', this.itemRow);
  this.itemRow = index;
  console.log('itemRow', this.itemRow);
  this.FormPrescription.controls.medicament.setValue(item);

}
  getMedicament(){
    
      this.submitted = true;

      if (this.FormMedicament.valid) {

         this.cardloader.active = true;
         const medicaments = Object.assign({}, this.FormMedicament.value);

         console.log('medicaments ', medicaments);
         this.bsService.recherchMedicamen(medicaments.txt_medicament).subscribe((res: any) => {
          console.log('reponse', res);
          if (res.status === 200) {
            this.listMedicament = res.medicaments;
            this.ordonnances.controls.forEach((element, index) => {
              this.ordonnances.removeAt(this.ordonnances.length - index);
            });
            this.annulerFormData();
            this.submitted = false;
            this.cardloader.active = false;
      }
    }); 
    }
  }

// ordonnance
get ordonnances(): FormArray {
  return this.ordonanceMedicale.get('ordonnances') as FormArray;
}
buildChildrenOrdonances(ordonnances: {quantite: string, posologie: string; medicament: string; }[] = []) {
  return this.fb.array(ordonnances.map((child) => this.fb.group(child)));
}

addOrdonnance(): void {

  /* console.log('get value', {quantite: this.FormMedicament.get('quantite').value,
  posologie: this.FormPrescription.get('posologie').value ,
   medicament: this.FormMedicament.get('txt_medicament').value }); */
  
   this.submitted = true;
   console.log('get value quantite', this.FormPrescription.get('quantite').value);
   console.log('get value posologie', this.FormPrescription.get('posologie').value);
   console.log('get value medicament', this.FormPrescription.get('medicament').value);
   console.log('get valid',this.FormPrescription.valid);
   console.log('FormPrescription',this.FormPrescription);
   if(this.FormPrescription.valid){
    console.log('get valid');
    this.ordonnances.push(this.fb.group({quantite: this.FormPrescription.get('quantite').value,
    posologie: this.FormPrescription.get('posologie').value ,
     medicament: this.FormPrescription.get('medicament').value }));
    this.activeBtnAddOrd = false;
   }
 
}

removeChildrenField(index): void {
  console.log('removeChildrenField index', index);
  console.log('this.ordonnances.length', this.ordonnances.length);
  if (this.ordonnances.length > 1 || this.ordonnances.length === 1) {
     this.ordonnances.removeAt(this.ordonnances.length - 1);
    } /* else {
              this.beneficiaires.patchValue([{ beneficiaire: 'Enfant', nom: null, prenom: null  ,  dateNaiss: null ,  checkboxsex:   [
                    {
                      name: 'M',
                      value: 'M',
                      checked: false
                    },
                    {
                      name: 'F',
                      value: 'F',
                      checked: false
                    }]
                }]);
           } */
}

    onTab(number) {
      this.FeedTab = false;
      this.ActivityTab = false;
      this.Prescriptions = false;

      if (number === '1') {
         this.Prescriptions = true;
        
      } else if (number === '2') {
        this.ActivityTab = true;
      } else if (number === '3') {
        this.FeedTab = true;
      }
    }
  // tslint:disable-next-line:no-unused-expression
  ngOnInit(): void {

    this.FormMedicament = this.fb.group({
      txt_medicament: ['', [Validators.required, Validators.minLength(2)]],
    });
    this.FormPrescription = this.fb.group({
      posologie: ['', [Validators.required, Validators.minLength(2)]],
      quantite: ['', [Validators.required, Validators.minLength(1)]],
    
      medicament: ['', [Validators.required, Validators.minLength(2)]],
    });

    this.ordonanceMedicale = this.fb.group({
      ordonnances: this.buildChildrenOrdonances(this.BSordonances),
    })

   /*  this.bsService.dataBulletinSoin$.forEach(event => {
      console.log('emit BulletinDeSoin', event);
      this.BulletinDeSoin = event; 

    });*/


    this.BulletinDeSoin = JSON.parse(localStorage.getItem('bulettin-soin')) ;
    console.log('BulletinDeSoin', this.BulletinDeSoin);
    /* if (this.BulletinDeSoin === undefined ||
      this.BulletinDeSoin === null ) {
      this.router.navigate([`edition-feuille-soin`]);
    } */

  }

  showSuccess() {
    this.toastr.success('Ajout d\'une ordonnance.', 'Ajouter avec succes!');
  }

  annulerFormData(): void {
    // this.formAdherent.reset();
    this.FormMedicament.reset();
    this.FormPrescription.reset();
    this.ordonanceMedicale.reset();
  }
}
